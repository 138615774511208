import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Button } from "../core";
import { IconArrowRight } from "../../assets/@feathericons";

/**
 * FeatureBlock
 */
const FeatureBlock = ({
  title,
  subTitle,
  text,
  buttonText,
  image,
  buttonPath,
  isReverse,
}) => {
  const _iconArrow = <IconArrowRight color={theme.colors.accent2} />;

  const imagePane = (
    <div className="w-auto lg:w-6/12 px-0 md:px-20 mb-40 lg:mb-0 mr-0 lg:mr-40">
      <img
        style={{
          border: "2px",
          borderStyle: "solid",
          borderColor: theme.colors.accent1_15,
        }}
        className="max-w-full max-h-full rounded"
        src={image}
        alt={`smartQA`}
      />
    </div>
  );

  const textPane = (
    <div className="w-auto lg:w-6/12 px-0 md:px-20 order-last lg:order-none">
      <div className="lg:max-w-400">
        <h3 className="text-md text-accent2 tracking-tight_xs pb-20">
          {title}
        </h3>
        <p className="text-md3 lg:text-md2 text-text3 leading-relaxed pb-30">
          {text}
        </p>
      </div>

      <div className="hidden">
        <Button
          to={buttonPath}
          icon={_iconArrow}
          label={buttonText}
          labelColor={theme.colors.accent2}
          frameColor={theme.colors.accent2_25}
        />
      </div>
    </div>
  );

  const content = isReverse ? (
    <div className="flex flex-col lg:flex-row justify-center items-start w-auto lg:w-10/12">
      {textPane}
      {imagePane}
    </div>
  ) : (
    <div className="flex flex-col lg:flex-row justify-center items-start w-auto lg:w-10/12">
      {imagePane}
      {textPane}
    </div>
  );

  return <>{content}</>;
};
FeatureBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  image: PropTypes.string,
  buttonPath: PropTypes.string,
  isReverse: PropTypes.bool,
};
FeatureBlock.defaultProps = {
  isReverse: false,
};

export default FeatureBlock;
