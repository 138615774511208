import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import { useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, Button, IconFrame, SectionHeading } from "../core";
import {
  bottomDiagonalBg_colorBg2,
  bottomDiagonalBg_bg1,
  bottomDiagonalBg_bg2,
} from "../../assets/graphics";

/**
 * CTASection
 */
const CTASection = ({ siteProps, diagonbalBgColor }) => {
  const intl = useIntl();
  const _sectionTitle = intl.formatMessage({
    id: "content.mainLanding.ctaSection.sectionTitle.title",
  });
  const _text = intl.formatMessage({
    id: "content.mainLanding.ctaSection.text",
  });
  const _registerEmailInputText = intl.formatMessage({
    id: "content.shared.forms.register.emailInputText",
  });
  const _registerButtonLabel = intl.formatMessage({
    id: "content.shared.forms.register.buttonLabel",
  });

  const _diagonalBgGraphic = {
    colorBg2: bottomDiagonalBg_colorBg2,
    bg1: bottomDiagonalBg_bg1,
    bg2: bottomDiagonalBg_bg2,
  };

  return (
    <section
      style={{
        backgroundImage: `url(${_diagonalBgGraphic[diagonbalBgColor]})`,
        backgroundPosition: "top left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
      className={cx(
        "bg-colorBg1",
        theme.spacing.sectionYTopExtra,
        theme.spacing.sectionYBottom
      )}>
      <Wrapper>
        <div className="flex flex-col justify-center items-center">
          <SectionHeading
            mainText={_sectionTitle}
            textColor={theme.colors.altText1}
            isHideSeparator={true}
          />

          <div className="w-auto md:w-5/12">
            <p
              className="text-md3 lg:text-md2 text-altText1_60 text-center leading-relaxed pb-30"
              style={{
                fontSize: 23,
              }}>
              {_text}
            </p>
          </div>

          <div className="h-20 bg-transparent" />

          <div className="flex flex-col">
            <input
              className="text-md4 rounded w-300 md:w-400 h-56 px-10 mb-8 outline-none"
              type="text"
              name="email"
              placeholder={_registerEmailInputText}
            />
            <Button
              mode="href"
              href={siteProps.appRegisterUrl}
              label={_registerButtonLabel}
              roundness="round"
              labelColor={theme.colors.altText1}
              frameColor={theme.colors.accent2}
              size="lg"
            />
          </div>

          <div className="h-20 bg-transparent" />
        </div>
      </Wrapper>
    </section>
  );
};
CTASection.propTypes = {
  diagonbalBgColor: PropTypes.oneOf(["colorBg2", "bg1", "bg2"]),
};
CTASection.defaultProps = {
  diagonbalBgColor: "colorBg2",
};

export default CTASection;
