import React from "react";
import PropTypes from "prop-types";
import { useIntl, Link } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, SectionHeading } from "../core";
import { IconCloud } from "../../assets/@feathericons";
import Accordion from "../shared/Accordion";
import { DataFaq, FaqList } from "./FaqSampleData";

/**
 * FaqSection
 */
const FaqSection = ({ title }) => {
  const intl = useIntl();

  // const viewAll = intl.formatMessage({
  //   id: "content.faq.viewAll",
  // });

  return (
    <section className={cx("bg-bg2", theme.spacing.sectionY)}>
      <Wrapper>
        <div className="flex flex-col">
          <SectionHeading
            mainText={title}
            textColor={theme.colors.accent1}
            lineColor={theme.colors.accent2}
          />

          <div className={cx("bg-transparent", theme.spacing.blockY)} />

          <div className="flex flex-col md:flex-row mt-5">
            <div className="md:w-3/5 mb-5">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10">
                {DataFaq.map((_item, idx) => (
                  <div
                    key={idx}
                    className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
                    <div className="p-10 flex items-center">
                      <IconCloud />
                      &nbsp;
                      <span className="pl-4 text-md4">
                        {_item[`Title_${intl.locale}`]}
                      </span>
                    </div>
                    <hr />
                    <div className="p-10">
                      {_item.Topics.map((SubItem, _idx) => (
                        <p
                          key={_idx}
                          className="text-gray-700 text-md4 py-4 truncate cursor-pointer">
                          {SubItem[`Title_${intl.locale}`]}
                        </p>
                      ))}
                      <Link to={`/faqs?faqId=${idx + 1}`}>
                        <div className="font-bold text-xl mt-3 mb-2 cursor-pointer">
                          {intl.locale === "en" ? "View All" : "すべて表示"}
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="md:w-2/5">
              <div className="container mx-auto">
                <div className="md:mx-8 rounded overflow-hidden">
                  {FaqList.map((_faq, idx) => (
                    <Accordion
                      key={idx}
                      title={_faq[`Title_${intl.locale}`]}
                      content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
FaqSection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
};
FaqSection.defaultProps = {};

export default FaqSection;
