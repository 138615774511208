import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, Button } from "../core";

/**
 * SubFeatureHeader
 */
const SubFeatureHeader = ({
  siteProps,
  title,
  text,
  image,
  bgColor,
  titleColor,
}) => {
  const intl = useIntl();
  const _registerEmailInputText = intl.formatMessage({
    id: "content.shared.forms.register.emailInputText",
  });
  const _registerButtonLabel = intl.formatMessage({
    id: "content.shared.forms.register.buttonLabel",
  });

  return (
    <section
      id="subHeader"
      style={{ backgroundColor: bgColor }}
      className={cx(theme.spacing.sectionYTight)}>
      <Wrapper>
        <div className="flex flex-col lg:flex-row items-center lg:items-start">
          <div className="flex flex-col items-center lg:items-start w-auto lg:w-5/12 pl-0 lg:pl-40">
            <h1
              style={{ color: titleColor }}
              className={cx(
                "font-medium",
                "text-md md:text-lg4 lg:text-lg3 tracking-tight_lg leading-tight lg:leading-none",
                "text-center lg:text-left",
                "pr-0 lg:pr-40",
                "mb-16"
              )}>
              {title}
            </h1>
            <p className="text-md3 leading-normal max-w-500 text-center lg:text-left">
              {text}
            </p>

            <div className={cx("bg-transparent", theme.spacing.blockY)} />

            <div className="flex flex-col w-full">
              <input
                className="text-md4 rounded w-full h-56 px-10 mb-8 outline-none"
                type="text"
                name="email"
                placeholder={_registerEmailInputText}
              />
              <Button
                mode="href"
                href={siteProps.appRegisterUrl}
                label={_registerButtonLabel}
                roundness="round"
                labelColor={theme.colors.altText1}
                frameColor={theme.colors.accent2}
                size="lg"
              />
            </div>
          </div>

          <div className={cx("bg-transparent", "w-auto lg:w-40")} />

          <div className="flex justify-center items-center w-auto lg:w-5/12">
            <div className="flex justify-center items-center px-80 lg:px-0 pt-40 lg:pt-0">
              <img
                style={{
                  border: "2px",
                  borderStyle: "solid",
                  borderColor: theme.colors.accent1_15,
                }}
                className="max-w-full max-h-full rounded"
                src={image}
                alt={`smartQA`}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
SubFeatureHeader.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
};
SubFeatureHeader.defaultProps = {
  bgColor: "black",
  titleColor: "white",
};

export default SubFeatureHeader;
