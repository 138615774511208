import React from 'react';

function PpEn() {
    return (
<div className="agreement-text">
Privacy Policy
InCloud Co., Ltd. (hereinafter referred to as the "Company") recognize the importance of protecting personal information and act on the protection of personal information (hereinafter referred to as the "Act on the Protection of Personal Information"). the following Privacy Policy (hereinafter referred to as the "Privacy Policy"). appropriate handling and protection. Unless otherwise provided in this Privacy Policy, the definition of terms in this Privacy Policy shall be in accordance with the provisions of the Personal Information Protection Act. 
1. Definition of personal information
In this Privacy Policy, personal information is information about a living individual and falls under any of the following items: 
(1) The name contained in the information, date of birth, other descriptions, etc. (documents, drawings or electromagnetic records, or recorded, or any matters expressed using voice, motion, or other methods) shall be provided. It can identify a specific individual (including those that can be easily matched with other information, thereby identifying a specific individual).  
(2) Contains personal identification codes
2. Purpose of use of personal information
The Company will use personal information for the following purposes:
	(1) 	 A service named smartQA (if the name of the service is changed for any reason, the service after the change will also be included. Other services provided by the Company (hereinafter referred to as "SmartQA".  ) for the provision of
	(2) 	 To communicate smoothly between users
	(3)) 	 To respond to information, inquiries, etc. about our services
	(4)) 	 To guide our products and services
	(5)) 	 Our Terms and Conditions, Policies, etc. regarding our 、 ポリシー services (hereinafter referred to as "Terms, etc.") to respond to any act that violates
	(6)	To resolve troubles and disputes that occur between users or between us and our users
	(7)) 	 To notify you of changes to the Terms and Conditions concerning our Services, etc. するため
	(8)) 	 To help improve our services and develop new services
	(9)) 	 For employment management and internal procedures (about personal information of officers and employees), selection and communication in recruitment activities (for applicants' personal information)
	(10)) 	 For shareholder management, the Companies Act, and other legal procedures (personal information of shareholders, stock holders, etc.)
	(11)) 	 To create statistical data processed in a format that does not identify individuals in relation to our services	
	(12)) 	 To provide it to third parties by joint use and other methods
	(13) 	 To exercise rights and fulfill obligations based on contracts and laws
	(14)) 	 For other purposes associated with the above purposes of use
3. Change of purpose of use of personal information
The Company may change the purpose of use of personal information within a reasonable recognition that it has relevance, and in the event of a change, the Individual (hereinafter referred to as the "person in question" who is the subject of personal information. or publicly disclosed.
4. Restrictions on the Use of Personal Information
The Company will not handle personal information beyond the scope necessary to achieve the purpose of use without the consent of the individual, except as permitted by the Personal Information Protection Law or other laws and regulations. However, this does not apply in the following cases:
	(1) 	 When required by law
	(2) 	 When it is necessary for the protection of a person's life, body or property, and it is difficult to obtain the consent of the person in question.
	(3) 	 When it is particularly necessary to improve public health or promote the sound development of children, and it is difficult to obtain the consent of the person in question.
	(4) 	 When it is necessary to cooperate with a national institution, a local government, or a person entrusted thereto to carry out the affairs prescribed by laws and regulations, and obtaining the consent of the person in question may interfere with the execution of said affairs.
5. Proper Acquisition of Personal Information
5.1 	 The Company will acquire personal information appropriately and will not acquire it by any means of deceit or other illegal conduct. 
5.2 	 The Company shall, except in the following cases, without obtaining the consent of the individual in advance, require personal information (as defined in Article 2, Paragraph 3 of the Personal Information Protection Act). ) does not get. 
	(1) 	 If any of the items in paragraph 4 are true
	(2) 	 When such consideration personal information is made public by the person in question, the organization of the country, the local government, the person listed in Article 76, 条第 paragraph 1 of the Personal Information Protection Act, and the person other specified in the Personal Information Protection Committee Regulations.
	(3) 	 When obtaining the personal information of the obvious consideration on the outline form by visualizing or taking a picture of the person in question
	(4) 	 第三者から When the provision of personal information required by a third party falls under any of the items of paragraph 7.1 by the third party said
5.3 	 When receiving personal information from a third party, the Company shall confirm the following matters pursuant to the provisions of the Personal Information Protection Committee Regulations.  Provided, however, that the provision of 第 such personal 場合 を除きます information by the third party falls under any of the items of paragraph 4 or 第 any of the items of paragraph 7.1.  
	(1) 	 The name or name and address of the third party, and in the case of a corporation, the name of the representative (if it is a non-legal entity and designated by の場合 a representative or manager, the representative or manager)
 	(2) 	 The circumstances of the acquisition of the personal information by the third party
6. Safe management of personal information
The Company shall provide necessary and appropriate supervision to its employees to ensure the safety management of personal information in response to risks such as loss, destruction, falsification, and leakage of personal information. In addition, when entrusting all or part of the handling of personal information, the Company shall conduct necessary and appropriate supervision to ensure the safe management of personal information at the subcontractor. 
7. Provision to third parties
7.1 	 The Company will not provide personal information to third parties without the prior consent of the individual, except in cases where any of the items in paragraph 4 are true. However, the following cases do not apply to the provision sought by third parties as set forth above.     
	(1) 	 When providing personal information in accordance with entrusting all or part of the handling of personal information within the scope necessary to achieve the purpose of use
	(2) 	 When personal information is provided in connection with the succession of the business due to merger or other reasons
	(3) 	 When joint use is made in accordance with the provisions of paragraph 8
7.2 	 第 Notwithstanding the provisions of Paragraph 7.1, the Company excludes foreign countries (excluding countries specified in the Rules of the Personal Information Protection Committee in accordance with Article 24 of the Personal Information Protection Act) in addition to cases where any of the items of paragraph 4 are applicable. third parties (excluding those who have established a system that meets the standards specified in the Personal Information Protection Committee Regulations in accordance with Article 24 of the Personal Information Protection Act). When providing personal information to the person in advance, the person in question shall obtain the consent of the person to be permitted to provide it to a third party in a foreign country. 
7.3 	 When we provide personal information to a third party, we will create and store records in accordance with Article 25 of the Personal Information Protection Act. 
7.4 	 When receiving personal information from a third party, the Company shall make necessary confirmations and make and store records for such confirmation in accordance with Article 26 of the Personal Information Protection Act. 
8. Joint Use
When the Company jointly uses personal information,(1) items of personal information used jointly,(2) the scope of the person who uses it jointly,(3) the purpose of use of the person who uses it, and (4) the name or name of the person responsible for the management of the above personal information shall be displayed separately on our website, etc., and (2) the personal information used jointly will be provided to the user provided to the user provided to the scope of the person who jointly uses it. 
9. Disclosure of Personal Information
When the Company requests the disclosure of personal information in accordance with the provisions of the Personal Information Protection Act, the Company will disclose to the person without delay after confirming that it is a request from the person in question (if such personal information does not exist) (we will notify you that when such personal information does not exist). )。 Provided, however, that this shall not apply if the Company is not obligated to disclose information under the Personal Information Protection Law or other laws and regulations. Please note that a fee1 (500 yen per case) 円）  is charged for the disclosure of personal information. The
10. Correction of personal information, etc.
The Company shall correct, add, or delete the contents of personal information in accordance with the Provisions of the Personal Information Protection Act (hereinafter referred to as "correction, etc.") for the reason that personal information is not true. In the event of a request, we will confirm that it is a request from the person himself, conduct necessary investigations within the scope necessary to achieve the purpose of use, correct the contents of personal information, etc. based on the results, and notify the individual to that effect (when he/she decides not to make a correction, etc.). )。 Provided, however, that this shall not apply if the Company does not undertake any obligation to correct, etc. due to the Act on the Protection of Personal Information and other laws and regulations.
11. Suspension of use of personal information, etc.
The Company suspends or erases the use of personal information from the person in question on the grounds that it has been handled beyond the scope of the purpose of use published in advance, or because it was obtained by false or other fraudulent means, in accordance with the provisions of the Personal Information Protection Act (hereinafter referred to as "suspension of use, etc.". suspension of provision of personal information (hereinafter referred to as "suspension" in accordance with the Provision of the Personal Information Protection Act on the grounds that personal information is provided to third parties without the consent of the individual concerned. In the case where the request is requested, if it is found that there is a reason for the request, we will notify the person in question that it will stop using or suspend the use of personal information without delay after confirming that it is a request from the person in question. Provided, however, that this shall not apply if the Company does not undertake a suspension of use or other laws and regulations under the Personal Information Protection Law or other laws and regulations. 
12. Handling  of Anonymous Lysis
12.1 	 The Company shall constitute anonymously processed information (meaning as provided in Article 2, Paragraph 9 of the Personal Information Protection Act, and constitute ります sought in Article 2, Paragraph 10 of the Act.  Less the same. personal information shall be processed in accordance with the standards set forth in the Personal Information Protection Committee Regulations.  
12.2 	 When anonymously processed information is created, the Company shall take measures for safety management in accordance with the standards set forth in the Personal Information Protection Committee Regulations.  
12.3 	 When anonymously processed information is created, the Company shall publish the items of information about individuals contained in the anonymously processed information as provided in the Personal Information Protection Committee Regulations. 
12.4 	 We provide anonymously processed information (including those created by the Company and provided by third parties). The same shall be true unless otherwise specified below.  When providing information to a third party, pursuant to the provisions of the Personal Information Protection Committee Regulations, we shall disclose in advance the items of information about individuals contained in the anonymously processed information provided to third parties and the method of providing them, and to the third party, we will make it clear to the third party that the information pertaining to such provision is anonymously processed information. 
12.5 	 In handling anonymously processed information, in order to identify the person concerned with personal information used to create anonymously processed information,(1) to match the anonymously processed information with other information, and (2) obtain information about the processing method 1 performed pursuant to the provisions of Article 第 36 or The Personal Information Protection Act (2) a description deleted from the personal information 若しくは or personal identification code or personal information protection act (2)(2)  shall 当該匿名加工情報 not be provided by a third party. 
12.6 	 The Company shall take necessary and appropriate measures for the safety management of anonymously processed information, the handling of complaints regarding the creation and other handling of anonymously processed information, and other necessary measures to ensure the proper handling of anonymously processed information, and shall endeavor to publicize the contents of such measures. るものとします  
13. Use of cookiesand other technologies の利用
Our services 、may usecookies and similar technologies. These technologies help us understand the usage of our services and contribute to the improvement of our services.  Users who want to disable cookies can disable cookies by changing their web browser settings. することができます   However, disabling 、cookies may not only allow you to use some of the features of our services.  
14. Contact Us
If you have any requests for disclosure, opinions, questions, complaints, or other inquiries regarding the handling of personal information, please contact the following.
●
InCloud Co., Ltd. ●
Phone 	 number:●
E-mail	:●
(The reception hours will be from weekdays ● to ●hours.) )
15. Continuous Improvement
The Company shall review the operational status of the handling of personal information from time to time, strive to continuously improve it, and may change this Privacy Policy as necessary.
16. Handling of personal data provided based on sufficient sex certification い
For personal data provided in accordance with the sufficient certification defined below, in addition to the provisions of the preceding paragraphs, the following provisions apply, and this section takes precedence if the provisions of this section differ from those in the preceding paragraphs.
16.1 	 Definition
	「EU」とは、欧州連合加盟国及び欧州経済領域（EEA: European Economic Area）協定に基づきアイスランド、リヒテンシュタイン及びノルウェーを含む、欧州連合（European Union）を意味します。
	「GDPR」とは、個人データの取扱いに係る自然人の保護及び当該データの自由な移転に関する欧州議会および欧州理事会規則（一般データ保護規則）（REGULATION OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC（General Data Protection Regulation））を意味します。
	「十分性認定」とは、GDPR第 45 条に基づき、欧州委員会が、国又は地域等を個人データについて十分な保護水準を確保していると認める決定を意味します。
16.2 	 If the PersonalData provided by withinthe EU contains information about sex life,sexual orientation or trade unions defined in theGDPR as "SpecialCategories of Personal Data",such information shall betreated as sensitive personal information. 
16.3 	 Personal data provided by the 当社が、Eu region, regardless of the period in which it is intended to be erased, shall be treated as retained personal data as set forth in the same paragraph, unless it falls under the "Shall enough to be specified by cabinet order as harming the public interest or other interests by revealing its existence or absence" in Article 2, Paragraph 7 of the Act on the Protection of Personal Information. 
16.4 	 When the Companyreceives personal data from within theEU based on sufficient accreditation, the Company shall confirm and record the circumstances of its acquisition, including the purposeof use specified when providing such personal data from within theEU, in accordance with the provisions of Article 1 26, Paragraph 1 and Paragraph 3 of the Personal Information Protection Act. 
16.5	  When the Company receives the provision of such personal data from other personal information handling business operators who have received the provision of personal data from within theEU based on sufficient recognition, the Company shall confirm and record the details of its acquisition, including the purpose of use specified when providing such personal data, pursuant to the provisions of Article 26, Paragraph 1 and Paragraph 3 of the Personal Information Protection Act. 
16.6 	 The Companyshall identify the purpose of use within the scope of the purpose of use identified at the time of initial or subsequent provision of the recorded personal data, which is confirmed and recorded in accordance with16.4 or 16.5, and shall use the personal data within the scope of the purpose of use. 
16.7 	 When providing personal data provided from within theEU to a third party in a foreign country, the Companyshall obtain the consent of the person in question based on paragraph7.2, after providing information about the situation of the transfer destination necessary for the person to make a decision pertaining to the consent, and agree to the person in question to provide personal data to a third party in a foreign country in advance. 
16.8 Personal data provided in accordance with sufficient certification from within the 	EU region, personal data such as processing method (such as descriptions deleted from personal information used for the creation of anonymous processing information, personal identification code, and information on the processing method performed pursuant to the provisions of Article 36, 条第 Paragraph 1 of the Personal Information Protection Act (only those that can restore such personal information using that information). I say. ) to consider anonymized information only if it is impossible for anyone to re-identify an anonymous individual. 


【Established on May 1, 12020】 年 日制定】
</div>
    )
}

export default PpEn;