import React, { useState, lazy, useEffect, Suspense } from "react";
import PropTypes from "prop-types";
import { useIntl } from "gatsby-plugin-intl";
import cx from "classnames";
import TosEn from "./docs/tos-en";
import TosJa from "./docs/tos-ja";
import PpEn from "./docs/pp-en";
import PpJa from "./docs/pp-ja";
import theme from "../../constants/theme";
import loadable from "@loadable/component";

import { Wrapper, SectionHeading } from "../core";

/**
 * ContentPageDocs
 */
const ContentPageDocs = ({ title, type }) => {
  const intl = useIntl();
  const TextData = loadable(() => import("./docs/" + type + "-" + intl.locale));

  return (
    <section className={cx("bg-bg2", theme.spacing.sectionY)}>
      <Wrapper>
        <div className="flex flex-col items-center">
          <SectionHeading
            mainText={title}
            textColor={theme.colors.accent1}
            lineColor={theme.colors.accent2}
          />

          <div className={cx("bg-transparent", theme.spacing.blockY)} />

          <div className="text-md3 leading-normal text-center">
            <TextData></TextData>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
ContentPageDocs.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
};
ContentPageDocs.defaultProps = {};

export default ContentPageDocs;
