import React from "react";

function TosEn() {
  return (
    <div className="agreement-text">
      <p>
        To use this service (defined in Article 2) provided by InCloud Co. Ltd.
        (hereinafter referred to as the "Company"), this user agreement
        (hereinafter referred to as the "Agreement") The items that must be
        complied with and the rights and obligations relationship between our
        company and all users are stipulated. If you use this service, please
        read the full text before agreeing to this agreement.
      </p>
      <p>&nbsp;</p>
      <p>
        <u>Article 1: Application of Terms</u>
      </p>
      <ol>
        <li>
          This Agreement applies to the rights and obligations relationship
          between the Company and Registered User (defined in Article 2)
          regarding the use of this service, and any relations related to the
          use of this service between Registered Users and our Company.
        </li>
        <li>
          The rules and regulations concerning the Service posted at any time on
          the Company's Website (as defined in Article 2) shall constitute a
          part of this Agreement.
        </li>
      </ol>
      <p>
        <u>Article 2: Definitions</u>
      </p>
      <p>
        The following terms used in this Agreement shall have the following
        meanings:
      </p>
      <p>
        (1) &nbsp;&nbsp;&nbsp; &nbsp;"QA Sheet" means a spreadsheet-like project
        in which members communicate in the Service.
      </p>
      <p>
        (2) &nbsp;&nbsp;&nbsp; &nbsp;"Monthly Contract" means a plan to pay for
        one month's use of the Service and to use the Service for one month.
      </p>
      <p>
        (3) &nbsp;&nbsp;&nbsp; &nbsp;"Maximum number of projects" means the
        number of projects that can proceed simultaneously in the Service.
      </p>
      <p>
        (4)) &nbsp;&nbsp; &nbsp;"Intellectual Property Rights" means copyright,
        patent rights, utility model rights, trademark rights, design rights,
        and other intellectual property rights (including the right to acquire
        those rights or to apply for registration, etc. for those rights).
      </p>
      <p>
        (5)) &nbsp;&nbsp; &nbsp;&ldquo;Website&rdquo; means the domain is{" "}
        <a href="http://www.sqa.jp">&nbsp;www.sqa.jp </a>&nbsp;(including
        subdomains associated with it). Operated by the Company (if the domain
        or content of our website changes for any reason, the revised website
        will be covered by this Agreement)
      </p>
      <p>
        (6)) &nbsp;&nbsp; &nbsp;"Applicants for Registration" means "applicants
        for registration" as defined in Article 3.&nbsp;
      </p>
      <p>
        (7)) &nbsp;&nbsp; &nbsp;"Registration Information" means the
        "registration information" as defined in Article 3.
      </p>
      <p>
        (8)) &nbsp;&nbsp; &nbsp;"Registered User" means an individual or legal
        entity who has been registered as a user of the Service in accordance
        with Article 3.&nbsp;
      </p>
      <p>
        (9) &nbsp;&nbsp;&nbsp; &nbsp;"Annual Contract" means a plan to pay for
        one year of the Service and to use the Service for one year.
      </p>
      <p>
        (10) &nbsp; &nbsp;"Project" means a single QA sheet or communication
        instance in this service. One project is equivalent to one QA
        sheet.&nbsp;
      </p>
      <p>
        (11) &nbsp; &nbsp;"Pro Plan" means a paid usage form in which you can
        use the Service in the Agreement in accordance with Article 4.
      </p>
      <p>
        (12) &nbsp; &nbsp;"Basic Plan" means a paid usage form in which you can
        use the Service in the Agreement in accordance with Article 4.
      </p>
      <p>
        (13) &nbsp; &nbsp;"Service" means a cloud-based service that allows
        multiple people to share, write, and manage tasks with multiple people
        with a spreadsheet-like Q&amp;A named SmartQA (if the name or content of
        the Service is changed for any reason, the changes will be covered by
        this Agreement).
      </p>
      <p>
        (14) &nbsp; &nbsp;"Free Plan" means the form of use that allows you to
        use the Service for free in the content sought in Article 4. &nbsp;
      </p>
      <p>
        (15) &nbsp; &nbsp;"Member" means an individual who uses the QA sheet of
        the Service.
      </p>
      <p>
        (16) &nbsp; &nbsp;"Use Agreement" means the "Use Agreement" as defined
        in Article 3, Paragraph 4.&nbsp;
      </p>
      <p>
        (17) &nbsp; &nbsp;"External SNS Services" meansa social networking
        service provided byFacebookand other operators that has the functionof
        authenticating registered users, disclosing friendships, and publishing
        content within the external social network, and is used to implement the
        Service.
      </p>
      <p>
        (18) &nbsp; &nbsp;"External SNS Operator" means a service provider for
        external SNS services.
      </p>
      <p>
        (19) &nbsp; &nbsp;"External SNS Terms of Use" means the terms and
        conditions that define the rights relationship between registered users
        and external SNS operators.
      </p>
      <p>
        <u>Article 3: Registration</u>
      </p>
      <ol>
        <li>
          Those who wish to use the Service (hereinafter referred to as
          "applicants for registration" agree to comply with these Terms of Use,
          and certain information (hereinafter referred to as "Registration
          Information") provided by the Company. you can apply to the Company to
          register for the use of the Service by providing it to the Company in
          a manner prescribed by the Company.
        </li>
        <li>
          Registration must be made by an individual or a legal entity using the
          Service, and as a general rule, a registration application by an agent
          is not permitted. In addition, applicants must provide us with
          truthful, accurate and up-to-date information when applying for
          registration.
        </li>
        <li>
          The Company may refuse to register if the person who applied for
          registration pursuant to paragraph (1) 1 falls under any of the
          following reasons.
        </li>
      </ol>
      <p>
        (1) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the Company determines that
        there is a risk of violating these Terms of Use
      </p>
      <p>
        (2) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When there is a false, incorrect, or
        omission of all or part of the registration information provided to the
        Company
      </p>
      <p>
        (3) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If you have cancelled your
        registration for the use of the Service in the past
      </p>
      <p>
        (4) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If the applicant is an individual and
        is either a minor, an adult guardian, a hosor or an auxiliary person,
        and has not obtained the consent of a legal representative, guardian, a
        hosor or auxiliary person, etc.
      </p>
      <p>
        (5) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Anti-social forces, etc.
        (gangmembers, gang members, gang associates, gang-related companies,
        general assembly shops, social movements, etc., special intelligence
        violence groups, etc., and other groups or individuals who pursue
        economic interests using violence, power or fraudulent methods. Less the
        same. or otherwise maintained, operated or involved in the operation or
        management of anti-social forces, etc.
      </p>
      <p>
        (6) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;In other cases where the Company
        reasonably determines that registration is not appropriate
      </p>
      <ol start="4">
        <li>
          When applying for registration for the use of the Service, the
          applicant shall select the plan and the term of the contract to be
          used for the Service.
        </li>
        <li>
          The Company shall determine whether or not to register applicants and
          use selected plans in accordance with the preceding paragraph or other
          standards of the Company, and notify the applicant of the registration
          and the use of such plan. With such notice, the registration as a
          registered user of the person who wants to register is completed, and
          the contract for the use of the Service in accordance with the terms
          and conditions of these Terms (hereinafter referred to as the "Use
          Agreement").) between the registered user and the Company.
        </li>
        <li>
          In the event of a change in the registration information, the
          registered user shall notify the Company of the changes and submit the
          materials requested by the Company in the manner specified by the
          Company without delay.
        </li>
      </ol>
      <p>
        <u>Article 4: Use of the Service and the contents of each plan</u>
      </p>
      <p>
        One. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Registered Users may use the
        Service in accordance with the methods set forth by the Company during
        the validity period of the Agreement.
      </p>
      <p>
        Two. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The following are the plans
        available for this service and its basic contents. In addition to the
        following, we may be able to use the functions that we add separately in
        each plan.
      </p>
      <p>(1) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Free Plan</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        上限プロジェクト数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        3個
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        メンバー&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        本サービスを利用できるメンバー3名以内
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ストレージ
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        なし（ファイルのアップロードのみ可能）
      </p>
      <p>(2) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Basic Plan</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        上限プロジェクト数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        10個
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        メンバー&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        各プロジェクトに参加できるメンバー5名以内
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ストレージ
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ファイルのアップロード用に250 MB
      </p>
      <p>(3) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Pro Plan</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        上限プロジェクト数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        無制限
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        メンバー&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        各プロジェクトに参加できるメンバー20名以内
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ストレージ
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ファイルアップロード用の10GB
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        その他&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        QAシートの内容をCSVにエクスポートできます。
      </p>
      <p>
        (4) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Pro Translation Plan (with automatic
        translation function)
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        上限プロジェクト数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        無制限
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Number of automatic translation &nbsp;
        &nbsp;projects Up to 10. &nbsp;If you have more than 10, you must delete
        the translated project and apply it to automatic translation. &nbsp;
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        メンバー&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        各プロジェクトに参加できるメンバー20名以内
      </p>
      <p>
        &nbsp;&nbsp;&nbsp; Automatic Translation Member &nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;Even if you have other plans for each automatic translation
        project, you can use the self-translation function.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ストレージ
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ファイルアップロード用の10GB
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        自動翻訳機能&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Google翻訳のAPIを利用した翻訳機能
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        その他&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        QAシートの内容をCSVにエクスポートできます。
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Three. &nbsp;&nbsp; &nbsp;Registered users may change their plans and
        contract periods in accordance with the manner stipulated by the
        Company. However, each change between the annual contract monthly
        contracts may not be applied at least one month prior to the expiration
        date of each contract.
      </p>
      <p>
        Four. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Registered users can register
        multiple individuals as members within the maximum number of members set
        for each plan, and can change members (deleting existing member
        registrations and registering another individual as a member).
      </p>
      <p>
        Five. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Registered Users shall allow
        members to comply with these Terms of Use and the Way of Using the
        Services set forth by the Company (in this case, the Registered User
        shall be replaced with "Member" as "Member" if necessary. The member's
        actions shall be deemed to be the act of the Registered User, and if the
        Member violates these Terms or the Method of Use of the Services
        prescribed by the Company, it shall be deemed a violation of the
        Registered User.
      </p>
      <p>
        <u>Article 5: Fees and payment methods</u>
      </p>
      <ol>
        <li>
          Registered users shall bear the usage fee (excluding consumption tax)
          as set forth below in exchange for the use of the Service.
        </li>
      </ol>
      <p>In the</p>
      <p>
        (1) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Free Plan &nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;Free of charge
      </p>
      <p>(2)) &nbsp;&nbsp; &nbsp;Basic Plan</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        月間契約&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        380円/月
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        年間契約&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        3,800円/年
      </p>
      <p>(3) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Pro Plan</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        月間契約&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        780円/月
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        年間契約&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        7,800円/年
      </p>
      <p>
        (4) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Pro Translation Plan (with automatic
        translation function)
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        年間契約&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        24,000円/年
      </p>
      <ol start="2">
        <li>
          After registered users have applied for the start of using and
          changing each plan,the usage fee for the plan is used by a credit card
          (includingStripe Stripe). Transfer money to a bank account designated
          separately by the Company (however, only if the registered user is a
          legal entity). or pay to the Company in a manner otherwise specified
          by the Company. In the case of credit card payment, the registered
          user shall pay the usage fee by the date specified separately by the
          Company when making an application by the method specified separately
          by the Company. The transfer fee and other expenses required for
          payment will be borne by the registered user.
        </li>
      </ol>
      <p>
        Three. &nbsp;&nbsp; &nbsp;The registered user shall pay the full usage
        fee within the contract period, regardless of whether or not he or she
        uses the Service. If the contract ends during the contract period, we
        will not refund the registered user even if there is a usage fee that
        has already been paid.
      </p>
      <ol start="4">
        <li>
          In the case of a change in the plan or term of the contract, if the
          use period of the plan before the change overlaps with the use period
          of the plan after the change, the plan-in change after the end of the
          period concerning the previous plan will be changed, and if the plan
          is changed during the period before the change, there will be no
          discount or refund of the usage fee. Similarly, changes to the monthly
          and annual contracts will not be changed until the end of the contract
          period prior to the change, and no discounts or refunds will be made
          for the monthly contract and annual contract.
        </li>
      </ol>
      <p>
        Five. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Even if the contract is
        terminated or terminated during the term of the Contract, the service
        will be paid in full and will not be refunded even if there is a usage
        fee already paid. Provided, however, that this shall not apply if the
        contract of use is terminated or terminated during the contract period
        due to reasons that should be attributed to the company's convenience or
        to the company's responsibilities.
      </p>
      <p>
        Six. &nbsp;If a registered user delays payment of the usage fee, the
        registered user shall pay the Company a delayed damage stake at a rate
        of 14.6%peryear.
      </p>
      <p>
        <u>Article 6: Manage your account information</u>
      </p>
      <ol>
        <li>
          Registered Users, at their own risk, the userID and password for the
          Service (hereinafter referred to as "Account Information"). management
          and storage of the system shall be managed and stored by third parties
          (excluding members). shall not be lent, transferred, changed name, or
          bought or sold.
        </li>
        <li>
          The Registered User shall be responsible for damages caused by
          insufficient management of account information, maluse in use, or the
          use of third parties, and the Company shall not be liable for any
          damages unless there is a reason to blame the Company.
        </li>
        <li>
          Registered Users shall notify the Company immediately and follow
          instructions from the Company if they are found to have stolen or used
          their account information by a third party.
        </li>
      </ol>
      <p>
        <u>Article 7: Prohibited acts</u>
      </p>
      <ol>
        <li>
          Registered Users shall not act in any of the following items when
          using the Service:
        </li>
      </ol>
      <p>
        (1) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Acts that infringe on the
        intellectual property rights, portrait rights, privacy rights, honor, or
        other rights or interests of the Company, other registered users,
        external SNS operators, or other third parties (including acts that
        directly or indirectly induce such infringement). )
      </p>
      <p>
        (2) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Acts related to criminal activity or
        acts that are contrary to public order and morals
      </p>
      <p>
        (3) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The act of sending obscene or harmful
        information to a youth
      </p>
      <p>
        (4) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The act of sending information about
        heterosexual relationships
      </p>
      <p>
        (5) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Acts that violate laws and
        regulations or internal rules of the industry association to which the
        Company or registered users belong.
      </p>
      <p>
        (6) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The act of transmitting information,
        including computer viruses or other harmful computer programs,
      </p>
      <p>
        (7) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The act of transmitting data greater
        than a certain amount of data specified by the Company through the
        Service
      </p>
      <p>
        (8) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Acts that are reasonably deemed to
        interfere with the operation of the Service by the Company
      </p>
      <p>
        (9) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Any other acts that the Company
        reasonably deems inappropriate
      </p>
      <span>
        2. The Company shall, in the event that the Company reasonably
        determines that the act of sending information by registered users in
        the Service falls under any of the items set forth in the preceding
        paragraph, it may delete, suspend transmission, or othermeasures for all
        or part of the information without prior notice to the registered user.
        The Company shall not be liable for any damages incurred by registered
        users based on measures taken by the Company in accordance with this
        section.
      </span>
      <p>
        <u>Article 8: Suspension of the Service, etc.</u>
      </p>
      <ol>
        <li>
          The Company may suspend or suspend all or part of the use of the
          Service without prior notice to registered users in the case of any of
          the following:
        </li>
      </ol>
      <p>
        (1) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the computer system related to
        the Service is inspected or maintained regularly or urgently
      </p>
      <p>
        (2) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When a computer, communication line,
        etc. are stopped due to an accident
      </p>
      <p>
        (3) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the service cannot be operated
        due to force majeure such as fire, power outage, or natural disaster
        change.
      </p>
      <p>
        (4) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;In the event of a trouble,
        interruption or suspension of service provision, suspension of
        cooperation with the Service, or a change of specifications, etc. in an
        external SNS service.
      </p>
      <p>
        (5) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;In other cases where the Company
        reasonably deems a stop or suspension necessary
      </p>
      <ol start="2">
        <li>
          The Company may terminate the provision of the Service at the
          company's reasonable discretion. In this case, the Company shall
          notify registered users in advance.
        </li>
        <li>
          The Company shall not be liable for any damages incurred by registered
          users in accordance with the measures taken by the Company in
          accordance with this Article.
        </li>
      </ol>
      <p>
        <u>Article 9: Burden of equipment, etc.</u>
      </p>
      <ol>
        <li>
          The preparation and maintenance of computers, smartphones, software,
          other devices, communication lines, and other communication
          environments necessary to receive the Service shall be at the expense
          and responsibility of registered users.
        </li>
        <li>
          Registered users shall take security measures at their own expense and
          responsibility, such as preventing computer viruses from infecting,
          unauthorized access, and leakage of information, depending on the
          environment in which they use the Service.
        </li>
        <li>
          The Company may store messages and other information sent and received
          by registered users for a certain period of time during the period of
          use and after the end of the contract, but the Company is not
          obligated to store such information and may delete such information at
          any time. For this reason, registered usersQA shall, upon their own
          responsibility and expenses, to back up and otherwise store
          information posted on the Service, such as QA Sheets.
        </li>
        <li>
          Registered Users shall take full care to ensure that the information
          held by registered users does not disappear or alter or damage
          devices, etc. when installing software, etc. on the registered user's
          computer, smartphone, etc. during the start of use of the Service or
          during the use of the Service, or by downloading from the Company's
          website or by other methods.
        </li>
      </ol>
      <p>
        <u>Article 10: Attribution of rights</u>
      </p>
      <ol>
        <li>
          All ownership and intellectual property rights relating to the
          Company's website and the Service belong to the Company or the person
          who has licensed the Service, and the license to use the Service based
          on the registration set forth in these Terms does not imply the
          transfer or license of intellectual property rights of the Company or
          the person licensed to the Company or the Company with respect to the
          Company, except as expressed in these Terms. Registered Users may
          infringe the intellectual property rights of the Company or the person
          who has licensed to us for any reason (including, but not limited to,
          disassembly, decompiling, or reverse engineering). shall not be used.
        </li>
        <li>
          Text, images, videos, and other data (hereinafter referred to as
          "transmitted information" on the Company's website or the Service)
          that registered users have posted or sent. The registered user's name
          (in the case of an individual) and the name of the registered user can
          be viewed by the Company during the period of the contract of use and
          the end of the period of use, and the information sent in order to
          provide and operate the Service and to develop a new service in the
          Company. (in the case of a legal entity), the name of the member, the
          affiliation of the member to be used, the e-mail address, and
          information other than statistics regarding the usage of the Service
          are used free of charge (including reproduction, copying,
          modification, relicensing to third parties, and all other use). shall
          be able to do so.
        </li>
      </ol>
      <p>
        Three. &nbsp;&nbsp; &nbsp;The Company may, to the reasonable extent,
        view the transmitted information and provide it to third parties in the
        case of any of the following items:
      </p>
      <p>
        (1)) &nbsp;&nbsp; &nbsp;契約者の同意を得た With the consent of the
        contractor
      </p>
      <p>
        (2) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When there is a の request from a
        court, a law enforcement agency or other administrative body ある
      </p>
      <p>
        (3) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When you are obligated to disclose in
        accordance with laws and regulations
      </p>
      <p>
        (4) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the Company reasonably
        determines that a registered user may or may violate the Terms of Use.
      </p>
      <p>
        (5) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If we reasonably judge that life,
        body, 、 and other important rights are necessary to protect
        と当社が合理的に判断する場合
      </p>
      <p>
        (6) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If there is an urgent need to
        maintain the Service 場合
      </p>
      <p>
        (7) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When there is a need to comply with
        the above items
      </p>
      <p>
        Four. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The Company shall be able to
        delete some or all of the information sent in the case of any of the
        following items: In this case, the Company shall not be liable for any
        deleted information, including the recovery of such information.
      </p>
      <p>(1)) &nbsp;&nbsp; &nbsp;With the consent of the registered user</p>
      <p>
        (2) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the Company reasonably
        determines that a registered user may or may violate the Terms of Use.
      </p>
      <p>
        (3) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the contract is terminated 場合
      </p>
      <p>(4) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the Service is terminated</p>
      <p>
        (5) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If a registered user using the Free
        Plan has not logged in to the Service for more than a year
        年以上ログインしなかった
      </p>
      <p>
        (6) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When there is a need to comply with
        the above items
      </p>
      <p>
        Five. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Notwithstanding the
        provisions of all the terms and conditions, the Company may, at our
        discretion, store the information sent to support registered users after
        the end of the Agreement.
      </p>
      <p>
        <u>Article 11: Cancellation of registration, etc.</u>
      </p>
      <ol>
        <li>
          The Company may temporarily suspend the use of the Service or cancel
          registration as a registered user without prior notice or notice, if
          the registered user falls under any of the following items.
        </li>
      </ol>
      <p>
        (1) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;In violation of any of these terms
      </p>
      <p>
        (2) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If it is found that there is a false
        fact in the registration information
      </p>
      <p>
        (3) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When you use or attempt to use the
        Service for the purpose or method that may cause damage to the Company,
        other registered users, external SNS operators, or other third parties.
      </p>
      <p>
        (4) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If SNS a registered user is no longer
        able to provide or cooperate with an external SNS operator for any other
        reason that violates the External SNS Terms of Use
      </p>
      <p>
        (5) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the operation of the Service is
        obstructed regardless of the means.
      </p>
      <p>
        (6) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When there is a request for
        suspension of payment or inability to pay, or the start of bankruptcy
        proceedings, the start of civil rehabilitation proceedings, the start of
        company rehabilitation proceedings, the start of special liquidation or
        the start of similar procedures.
      </p>
      <p>
        (7) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When you have received a
        non-disciplinary disposition for a bill or check that you have drawn or
        undertaken, or when you receive a suspension of transactions or other
        measures similar to that of the Exchange of Bills.
      </p>
      <p>
        (8) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When there is a petition for
        foreclosure, provisional foreclosure, provisional disposition,
        enforcement or auction
      </p>
      <p>
        (9) &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;In the case of inarron of tax and
        public affairs
      </p>
      <p>
        (10) &nbsp; &nbsp;In the case of death or the trial of the start of
        guardianment, the start of the hosa or the auxiliary start,
      </p>
      <p>(11) &nbsp;&nbsp; &nbsp;If you do not pay the usage fee</p>
      <p>
        (12) &nbsp; &nbsp;If it falls under each item of Article 3 3, Paragraph
        3
      </p>
      <p>
        (13)) &nbsp;In other cases where the Company reasonably determines that
        it is inappropriate to continue registration as a registered user
      </p>
      <ol start="2">
        <li>
          In the event of any of the reasons of the preceding paragraph, the
          registered user shall, of course, lose the profit of the due date for
          all of the debtowed to the Company and immediately pay all debts to
          the Company.
        </li>
        <li>
          Registered Users may cancel the registration of registered users by
          notifying us in a manner prescribed by the Company.
        </li>
        <li>
          The Company shall not be liable for any damages incurred by registered
          users as a result of the conduct performed by the Company in
          accordance with this Article.
        </li>
        <li>
          In the case of cancellation of registration of registered users in
          accordance with this Article, the Registered User shall, in accordance
          with the instructions of the Company, return, dispose of, or dispose
          of software, manuals, or other items related to the Service provided
          by the Company.
        </li>
      </ol>
      <p>
        <u>Article 12: Repudiation and Disclaimer of Warranty</u>
      </p>
      <ol>
        <li>
          The Company makes no warranties that 当社は、the contents ofthe QA
          Sheet and the data uploaded in the Service will be successfully stored
          or lost in the Service. Registered Users shall be stored at their own
          risk by backing up the contents oftheQA Sheet and the data uploaded in
          the Service, and the Company shall not be liable for any thing, even
          if the contents ofthe QA sheet and the data uploaded in the Service
          have not been successfully stored or lost, except as there are reasons
          to be attributed to the Company's responsibility.
        </li>
        <li>
          The Service is provided as is and the Company does not warrant the
          Service, including fitness for a particular purpose, commercial
          usefulness, completeness, continuity, etc. In addition,QA the Company
          shall not be liable for the accuracy and legality of the contents of
          the data uploaded and written in the QA Sheet and the Service.
        </li>
      </ol>
      <p>
        Three. &nbsp;&nbsp; &nbsp;The Company makes no warranties to registered
        users beyond what is provided in these Terms, even if the Registered
        User obtains any information directly or indirectly from the Company
        regarding the Service, the Company's website, other registered users of
        the Service, or other matters.
      </p>
      <p>
        Four. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Although this service may
        cooperate with external SNS services, the Company does not guarantee
        such cooperation and assumes no responsibility for any reasons that
        should be attributed to the Company for any hindrance to cooperation
        with external SNS services.
      </p>
      <p>
        Five. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;When the Service is in
        conjunction with an external SNS service, the Registered User shall
        comply with the External SNS Terms of Use at his/her own expense and
        responsibility, and the Company shall not be liable for such disputes,
        even if there is a dispute between the registered user and the external
        SNS operator engaged in the external SNS SNS service.&nbsp;
      </p>
      <p>
        Six. &nbsp;Registered Users shall investigate on the basis of their own
        responsibility and expense whether the use of the Service violates
        applicable laws and regulations of the Registered Users, internal rules
        of industry associations, etc., and the Company does not guarantee that
        the use of the Service by registered users conforms to applicable laws
        and regulations, industry association internal rules, etc.
      </p>
      <p>
        Seven. &nbsp;&nbsp; &nbsp;The Company shall process and resolve
        transactions, communications, disputes, etc. arising between registered
        users and other registered users, external SNS operators, and other
        third parties in connection with the Service or the Company's website,
        and the Company shall not be liable for such matters, except as there
        are reasons to be attributed to the Company.
      </p>
      <p>
        Eight. &nbsp;&nbsp;&nbsp; &nbsp;The Company shall not be liable to
        compensate the Company for any interruption, suspension, termination,
        inability to use or change the provision of the Service, deletion or
        loss of registered user's message or transmission information,
        cancellation of registration of registered users, loss of data due to
        the use of the Service, equipment failure or damage, or any other damage
        incurred by registered users in connection with the Service.
      </p>
      <p>
        Nine. &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Even if a link to another website
        is provided from our website or from another website to the Company's
        website, the Company shall not be liable for any reason to attribute the
        company's responsibility to the website other than the Company's website
        and the information obtained from it.
      </p>
      <p>
        Ten.. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;Even if the Company is liable for damages to registered users for
        any reason, such as the application of the Consumer Contract Act or
        other enforcement laws, our liability shall limit the total amount of
        the Service usage fee actually received from registered users over the
        past six months, retroactive to the time of the cause of the damage.
      </p>
      <p>
        <u>Article 13: Responsibility for user compensation, etc.</u>
      </p>
      <ol>
        <li>
          Registered Users shall compensate the Company for any damages incurred
          by the Company in connection with the use of the Services by violating
          these Terms.
        </li>
        <li>
          In the event that a registered user receives a complaint from another
          registered user, an external SNS operator, or other third party in
          connection with the Service or encounters a dispute with such a
          person, he/she shall immediately notify the Company of the contents,
          process such claims or disputes at the expense and responsibility of
          the registered user, and report the progress and results to the
          Company based on the request of the Registered User.
        </li>
        <li>
          If the Company receives any claim sought by other registered users,
          external SNS operators, or other third parties for infringement or
          other reasons in connection with the use of the Service by registered
          users, the registered user shall be compensated for the amount of
          money that the Company was forced to pay to such third parties based
          on such claim.
        </li>
      </ol>
      <p>
        <u>Article 14: Confidentiality</u>
      </p>
      <ol>
        <li>
          "Confidential Information" in these Terms means all information
          relating to the Company's technology, sales, business, finance,
          organization, and other matters that registered users have provided or
          disclosed or obtained from the Company in connection with the Use
          Agreement or the Service. However,(1) when or when the provision or
          disclosure from the Company is made, it has already been generally
          known, or has already been obtained,(2) after providing or disclosing
          or obtaining from the Company, by publications or others due to
          reasons that cannot be attributed to its own responsibility Any
          known,(3) obtained legally without obligation severance from a third
          party authorizedto provide or disclose,(4) developed alone without
          confidential information,or(5) those confirmed in writing to the
          necessary date of confidentiality from the Company shall be excluded
          from the confidential information.
        </li>
        <li>
          Registered Users shall use confidential information only for the
          purpose of using the Service and shall not provide, disclose or
          divulge confidential information of the Company to third parties
          without the company's written consent.
        </li>
        <li>
          第 In spite of the provisions of paragraph 2, registered users may
          disclose confidential information in accordance with orders, requests
          or requests of laws, courts or government agencies. However, if there
          is such an order, request, or request, we must promptly notify the
          Company.
        </li>
        <li>
          When a registered user reproduces a document containing confidential
          information or a magnetic recording medium, etc., he/she shall obtain
          the company's written consent in advance, and shall strictly manage
          the reproductions in accordance with paragraph 2.&nbsp;
        </li>
        <li>
          Registered Users shall, without delay, return or dispose of
          confidential information and other recording materials and all copies
          of the recording medium, including or containing confidential
          information, without delay, when requested by the Company.
        </li>
      </ol>
      <p>
        <u>Article 15: Handling of personal information, etc.</u>
      </p>
      <ol>
        <li>
          Personal information of registered users by the Company ("Personal
          Information" as specified in Article 2, Paragraph 1 of the Act on the
          Protection of Personal Information. Regarding the handling of the
          shall be due to the provisions of our privacy policy separately,
          registered users agree that the Company will handle the personal
          information of registered users in accordance with this Privacy
          Policy.
        </li>
        <li>
          The Company shall, at its discretion, use and disclose the
          information, data, etc. provided to the Company by registered users as
          statistical information in a manner that does not identify an
          individual, and registered users shall not object to this.&nbsp;
          Provided, however, that the Company shall strictly keep information
          and materials about the project provided to the Company by registered
          users as confidential and shall not provide it to third parties for
          any purpose.
        </li>
      </ol>
      <p>
        <u>Article 16: Validity period</u>
      </p>
      <p>
        One. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The Terms of Use shall remain
        in effect between the Company and the Registered User until the date of
        completion of the registration under Article 3 for the Registered User,
        and until the expiration of the contract period of each plan, the date
        on which the registration of the registered user is cancelled or the
        date of termination of the provision of the Service.
      </p>
      <p>
        Two. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The term of the monthly
        contract shall be one month from the start date of use that the Company
        notifies registered users, and the term of the annual contract shall be
        one year from the start date of use that the Company notifies the
        registered user.&nbsp; Although there is no special term for free plans,
        if there is a need for storage and other reasonable reasons for the
        company, we may terminate the contract with the user under the free plan
        by notifying the registered user 144 days in advance. In this case, the
        Company shall not be liable for any damages incurred by registered users
        in relation to the termination.
      </p>
      <p>
        Three. &nbsp;&nbsp; &nbsp;As long as the registered user does not offer
        the Company to terminate the contract by the cancellation procedure
        stipulating in Article 1 1,1 paragraph 3, the monthly contract shall be
        renewed for one month, and in the case of an annual contract, the
        company will do the same.
      </p>
      <p>
        <u>Article 17: Changes to these Terms, etc.</u>
      </p>
      <ol>
        <li>
          The Company shall be free to change the contents of the Service.
        </li>
        <li>
          The Company shall use these Terms (including rules and regulations
          concerning the Services posted on the Company's website). Same in this
          section below.&nbsp; you can change. In the event of a change to these
          Terms, the Company shall announce the content of the change and the
          effectiveness of the change in a manner prescribed by the Company by
          the time of its effectiveeffect. If a registered user uses the Service
          after the announced effective date or fails to take the procedure of
          cancellation of registration within the company's prescribed period,
          the registered user shall be deemed to have agreed to the change of
          these Terms.
        </li>
      </ol>
      <ul>
        <li>Contact / Notification</li>
      </ul>
      <p>
        Inquiries about the Service, other communications or notices from
        registered users to the Company, notices regarding changes in these
        Terms, and other communications or notices from the Company to
        registered users shall be made in the manner prescribed by the Company.
        The Company shall not respond to communications from methods other than
        the methods specified by the Company.
      </p>
      <ul>
        <li>Transfer of these Terms, etc.</li>
      </ul>
      <ol>
        <li>
          Registered Users may not transfer, transfer, secure, or otherwise
          dispose of any third party without the company's prior written
          consent, with respect to their position or obligations under the Terms
          of Use.
        </li>
        <li>
          The Company transfers the business of the Service to a third party
          (shall not be charged with business transfer, company split, or other
          aspects. In the event of such transfer, the registered user shall be
          able to transfer the status under the use contract, the rights and
          obligations under these Terms, registration information of registered
          users, and other customer information to the assignee of the transfer,
          and the registered user shall agree in advance in this section for
          such transfer.
        </li>
      </ol>
      <ul>
        <li>Full agreement</li>
      </ul>
      <p>
        These Terms constitute a complete agreement between the Company and
        registered users with respect to the matters contained in these Terms,
        and supersede prior agreements, representations and understandings of
        the matters contained in these Terms, whether verbally or in writing,
        with respect to the matters contained in these Terms.
      </p>
      <ul>
        <li>Separation possibility</li>
      </ul>
      <p>
        Even if any provision or part of these Terms is deemed invalid or
        unenforceable by the Consumer Contract Act or other laws and
        regulations, the remainder of the provisions of these Terms and the
        remainder of the provisions deemed invalid or unenforceable shall remain
        in full force, and the Company and registered users shall amend the
        terms or parts of such invalid or unenforceable to the extent necessary
        to have enforceable power. The Company shall endeavor to ensure the
        intent of such invalid or unenforceable provisions or parts, as well as
        the legal and economic ally, to ensure the same effect.
      </p>
      <ul>
        <li>Survival Regulations</li>
      </ul>
      <p>
        Article 4 4, Paragraph 5, 項、第 Article 5 (only if there is no unpaid).
        article 6, paragraph 2, Article 7, 7 Paragraph 2, Article 項、第 8, 条第
        Paragraph 3, 項、第 Article 9, Article 10, 条、第 Article 11, Paragraph
        条、第 2 2, Paragraph 4 and Article 5, Paragraph 12&nbsp; Articles 1 1-1
        5, Article 16, Paragraph 2, and articles 19 through 23 shall remain in
        effect after the termination of the Contract of Use. However, Article 14
        shall last only for two years after the end of the contract of
        use.&nbsp;
      </p>
      <ul>
        <li>Governing Law and Court of Jurisdiction</li>
      </ul>
      <p>
        The governing law of these Terms shall be japanese law, and for all
        disputes arising out of or relating to these Terms, the Tokyo District
        Court or the Tokyo Simple Court shall be the exclusive court of
        exclusive jurisdiction of the first instance.
      </p>
      <ul>
        <li>Consultation resolution</li>
      </ul>
      <p>
        The Company and registered users shall promptly resolve matters not
        provided for in these Terms or in the event of any doubt in their
        interpretation of these Terms, in consultation with each other in
        accordance with the principle of integrity.
      </p>
      <br></br>
      <p style={{ textAlign: "right" }}>【Established on May 1, 2020】</p>
    </div>
  );
}

export default TosEn;
