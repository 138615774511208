import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { SectionHeading, Wrapper } from "../core";
import { FeatureBlock } from "../pageShared";

/**
 * StandOutFeatureSection
 */
const StandOutFeatureSection = ({
  sectionId,
  sectionTitle,
  title,
  text,
  image,
  bgColor,
  sectionTitleColor,
  isReverse,
}) => {
  return (
    <section
      id={sectionId}
      style={{ backgroundColor: bgColor }}
      className={cx(theme.spacing.sectionY)}>
      <Wrapper>
        <div className="flex justify-center items-center">
          <div className="w-8/12">
            <SectionHeading
              mainText={sectionTitle}
              textColor={sectionTitleColor}
              isHideSeparator="true"
            />
          </div>
        </div>

        <div className={cx("bg-transparent", theme.spacing.blockYTight)} />

        <div className="flex flex-col justify-center items-center">
          <FeatureBlock
            title={title}
            text={text}
            image={image}
            isReverse={isReverse}
          />
        </div>
      </Wrapper>
    </section>
  );
};
StandOutFeatureSection.propTypes = {
  sectionTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  bgColor: PropTypes.string,
  sectionTitleColor: PropTypes.string,
  isReverse: PropTypes.bool,
};
StandOutFeatureSection.defaultProps = {
  bgColor: "white",
  sectionTitleColor: "black",
  isReverse: false,
};

export default StandOutFeatureSection;
