import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, SectionHeading } from "../core";

/**
 * ContentPage
 */
const ContentPage = ({ title, content }) => {
  const intl = useIntl();

  const _mapContent = arr => {
    return arr.map(item => {
      return (
        <div className="flex flex-col w-full pb-40">
          <h3 className="text-accent2 text-md2 tracking-tight_sm pb-20">
            {item.heading}
          </h3>
          <p className="text-text1 text-md4 leading-normal">{item.text}</p>
        </div>
      );
    });
  };

  const _underConstruction = () => {
    return (
      <div className="flex flex-col w-full pb-40">
        <p className="text-text1 text-md4 leading-normal text-center">
          Under construction.
        </p>
      </div>
    );
  };

  return (
    <section className={cx("bg-bg2", theme.spacing.sectionY)}>
      <Wrapper>
        <div className="flex flex-col items-center">
          <SectionHeading
            mainText={title}
            textColor={theme.colors.accent1}
            lineColor={theme.colors.accent2}
          />

          <div className={cx("bg-transparent", theme.spacing.blockY)} />

          {content !== undefined ? _mapContent(content) : _underConstruction()}
        </div>
      </Wrapper>
    </section>
  );
};
ContentPage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
};
ContentPage.defaultProps = {};

export default ContentPage;
