import React from "react";

function PpJa() {
  return (
    <div className="agreement-text">
      <p>
        InCloud株式会社（以下「当社」といいます。）は、個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。なお、本プライバシーポリシーにおいて別段の定めがない限り、本プライバシーポリシーにおける用語の定義は、個人情報保護法の定めに従います。
      </p>
      <p>
        <u>1.</u>
        <u>　個人情報の定義</u>
      </p>
      <p>
        本プライバシーポリシーにおいて、個人情報とは、生存する個人に関する情報であって、次の各号のいずれかに該当するものをいいます。
      </p>
      <p>
        (1)
        当該情報に含まれる氏名、生年月日その他の記述等（文書、図画若しくは電磁的記録に記載され、若しくは記録され、又は音声、動作その他の方法を用いて表された一切の事項をいいます。）により特定の個人を識別することができるもの
        （他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）
      </p>
      <p>(2)　個人識別符号が含まれるもの</p>
      <p>
        <u>2.</u>
        <u>　個人情報の利用目的　　</u>
      </p>
      <p>当社は、個人情報を以下の目的で利用いたします。</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (1)&nbsp;&nbsp;&nbsp;&nbsp;
        smartQAという名称のサービス（理由の如何を問わず、サービスの名称が変更された場合、変更後のサービスも含みます。）その他当社が提供するサービス（以下SmartQAとあわせて「当社サービス」といいます。）の提供のため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (2)&nbsp;&nbsp;&nbsp;&nbsp;
        ユーザー間の円滑な連絡を行うため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (3)&nbsp;&nbsp;&nbsp;&nbsp;
        当社サービスに関するご案内、お問い合せ等への対応のため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (4)&nbsp;&nbsp;&nbsp;&nbsp;
        当社の商品、サービス等のご案内のため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (5)&nbsp;&nbsp;&nbsp;&nbsp;
        当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (6)&nbsp;&nbsp;&nbsp;&nbsp;
        ユーザー間又は当社及びユーザー間に生じたトラブル及び紛争の解決のため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (7)&nbsp;&nbsp;&nbsp;&nbsp;
        当社サービスに関する規約等の変更などを通知するため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (8)&nbsp;&nbsp;&nbsp;&nbsp;
        当社サービスの改善、新サービスの開発等に役立てるため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (9)&nbsp;&nbsp;&nbsp;&nbsp;
        雇用管理及び社内手続のため（役職員の個人情報について）、人材採用活動における選考及び連絡のため（応募者の個人情報について）
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (10)&nbsp;&nbsp;&nbsp;
        株主管理、会社法その他法令上の手続対応のため（株主、新株予約権者等の個人情報について）
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (11)&nbsp;&nbsp;&nbsp;
        当社サービスに関連して、個人を識別できない形式に加工した統計データを作成するため&nbsp;
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (12)&nbsp;&nbsp;&nbsp;
        共同利用等その他の方法により第三者に提供するため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (13)&nbsp;&nbsp;&nbsp;
        契約や法律等に基づく権利の行使や義務の履行のため
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (14)&nbsp;&nbsp;&nbsp;
        その他、上記利用目的に付随する目的のため
      </p>
      <p>
        <u>3.</u>
        <u>　個人情報利用目的の変更</u>
      </p>
      <p>
        当社は、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には個人情報の主体である個人（以下「本人」といいます。）に通知し又は公表します。
      </p>
      <p>
        <u>4.</u>
        <u>　個人情報利用の制限</u>
      </p>
      <p>
        当社は、個人情報保護法その他の法令により許容される場合を除き、本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (1)&nbsp;&nbsp;&nbsp;&nbsp; 法令に基づく場合
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (2)&nbsp;&nbsp;&nbsp;&nbsp;
        人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (3)&nbsp;&nbsp;&nbsp;&nbsp;
        公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; (4)&nbsp;&nbsp;&nbsp;&nbsp;
        国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
      </p>

      <p>
        <u>5.</u>
        <u>　個人情報の適正な取得</u>
      </p>
      <p>
        5.1&nbsp;&nbsp;&nbsp;&nbsp;
        当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。
      </p>
      <p>
        5.2&nbsp;&nbsp;&nbsp;&nbsp;
        当社は、次の場合を除き、あらかじめ本人の同意を得ないで、要配慮個人情報（個人情報保護法第2条第3項に定義されるものを意味します。）を取得しません。
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (1)&nbsp;&nbsp;&nbsp;&nbsp; 第4項各号のいずれかに該当する場合
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (2)&nbsp;&nbsp;&nbsp;&nbsp;
        当該要配慮個人情報が、本人、国の機関、地方公共団体、個人情報保護法第76条第1項各号に掲げる者その他個人情報保護委員会規則で定める者により公開されている場合
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (3)&nbsp;&nbsp;&nbsp;&nbsp;
        本人を目視し、又は撮影することにより、その外形上明らかな要配慮個人情報を取得する場合
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (4)&nbsp;&nbsp;&nbsp;&nbsp;
        第三者から要配慮個人情報の提供を受ける場合であって、当該第三者による当該提供が第7.1項各号のいずれかに該当するとき
      </p>
      <p>
        5.3&nbsp;
        当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護委員会規則で定めるところにより、次に掲げる事項の確認を行います。ただし、当該第三者による当該個人情報の提供が第4項各号のいずれかに該当する場合又は第7.1項各号のいずれかに該当する場合を除きます。
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (1)&nbsp;&nbsp;&nbsp;&nbsp;
        当該第三者の氏名又は名称及び住所、並びに法人の場合はその代表者（法人でない団体で代表者又は管理人の定めのあるものの場合は、その代表者又は管理人）の氏名
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (2)&nbsp;&nbsp;&nbsp;&nbsp; 当該第三者による当該個人情報の取得の経緯
      </p>
      <p>
        <u>6.</u>
        <u>　個人情報の安全管理</u>
      </p>
      <p>
        当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
      </p>
      <p>
        <u>7.</u>
        <u>　第三者提供　</u>
      </p>
      <p>
        7.1&nbsp;&nbsp;&nbsp;&nbsp;
        当社は、第4項各号のいずれかに該当する場合を除くほか、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (1)&nbsp;&nbsp;&nbsp;&nbsp;
        利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (2)&nbsp;&nbsp;&nbsp;&nbsp;
        合併その他の事由による事業の承継に伴って個人情報が提供される場合
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        (3)&nbsp;&nbsp;&nbsp;&nbsp; 第8項の定めに従って共同利用する場合
      </p>
      <p>
        7.2&nbsp;&nbsp;&nbsp;&nbsp;
        第7.1項の定めにかかわらず、当社は、第4項各号のいずれかに該当する場合を除くほか、外国（個人情報保護法第24条に基づき個人情報保護委員会規則で指定される国を除きます。）にある第三者（個人情報保護法第24条に基づき個人情報保護委員会規則で指定される基準に適合する体制を整備している者を除きます。）に個人情報を提供する場合には、あらかじめ外国にある第三者への提供を認める旨の本人の同意を得るものとします。
      </p>
      <p>
        7.3&nbsp;&nbsp;&nbsp;&nbsp;
        当社は、個人情報を第三者に提供したときは、個人情報保護法第25条に従い、記録の作成及び保存を行います。
      </p>
      <p>
        7.4&nbsp;&nbsp;&nbsp;&nbsp;
        当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護法第26条に従い、必要な確認を行い、当該確認にかかる記録の作成及び保存を行うものとします。
      </p>
      <p>
        <u>8.</u>
        <u>　共同利用</u>
      </p>
      <p>
        当社が、個人情報を共同利用する場合は、(1)共同して利用される個人情報の項目、(2)共同して利用する者の範囲、(3)利用する者の利用目的及び(4)上記個人情報の管理について責任を有する者の氏名又は名称を別途当社のウェブサイト等に表示するものとし、共同利用される個人情報を上記(2)共同して利用する者の範囲に定める利用者に提供いたします。
      </p>
      <p>
        <u>9.</u>
        <u>　個人情報の開示</u>
      </p>
      <p>
        当社は、本人から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、本人ご自身からのご請求であることを確認の上で、本人に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり500円）を頂戴しておりますので、あらかじめ御了承ください。］
      </p>
      <p>
        <u>10.</u>
        <u>　個人情報の訂正等</u>
      </p>
      <p>
        当社は、本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、本人ご自身からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨を本人に通知します（訂正等を行わない旨の決定をしたときは、本人に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。
      </p>
      <p>
        <u>11.</u>
        <u>　個人情報の利用停止等</u>
      </p>
      <p>
        当社は、本人から、本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合、又は個人情報がご本人の同意なく第三者に提供されているという理由により、個人情報保護法の定めに基づきその提供の停止（以下「提供停止」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、本人ご自身からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等又は提供停止を行い、その旨を本人に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等又は提供停止の義務を負わない場合は、この限りではありません。
      </p>
      <p>
        <u>12.</u>
        <u>　匿名加工情報の取扱い</u>
      </p>
      <p>
        12.1&nbsp;&nbsp;
        当社は、匿名加工情報（個人情報保護法第2条第9項に定めるものを意味し、同法第2条第10項に定める匿名加工情報データベース等を構成するものに限ります。以下同じ。）を作成するときは、個人情報保護委員会規則で定める基準に従い、個人情報を加工するものとします。
      </p>
      <p>
        12.2&nbsp;&nbsp;
        当社は、匿名加工情報を作成したときは、個人情報保護委員会規則で定める基準に従い、安全管理のための措置を講じます。
      </p>
      <p>
        12.3&nbsp;&nbsp;
        当社は、匿名加工情報を作成したときは、個人情報保護委員会規則で定めるところにより、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。
      </p>
      <p>
        12.4&nbsp;&nbsp;
        当社は、匿名加工情報（当社が作成したもの及び第三者から提供を受けたものを含みます。以下別段の定めがない限り同様とします。）を第三者に提供するときは、個人情報保護委員会規則で定めるところにより、あらかじめ、
        第三者に提供される匿名加工情報に含まれる個人に関する情報の項目及びその提供の方法について公表するとともに、当該第三者に対して、当該提供に係る情報が匿名加工情報である旨を明示します。
      </p>
      <p>
        12.5&nbsp;&nbsp;
        当社は、匿名加工情報を取り扱うに当たっては、匿名加工情報の作成に用いられた個人情報に係る本人を識別するために、(1)匿名加工情報を他の情報と照合すること、及び(2)当該個人情報から削除された記述等若しくは個人識別符号又は個人情報保護法第36条第1項の規定により行われた加工の方法に関する情報を取得すること（(2)は第三者から提供を受けた当該匿名加工情報についてのみ）を行わないものとします。
      </p>
      <p>
        12.6&nbsp;&nbsp;
        当社は、匿名加工情報の安全管理のために必要かつ適切な措置、匿名加工情報の作成その他の取扱いに関する苦情の処理その他の匿名加工情報の適正な取扱いを確保するために
        必要な措置を自ら講じ、かつ、当該措置の内容を公表するよう努めるものとします。
      </p>
      <p>
        <u>13.</u>
        <u> Cookie（クッキー）その他の技術の利用</u>
      </p>
      <p>
        当社のサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による当社のサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当社のサービスの一部の機能をご利用いただけなくなる場合があります。
      </p>
      <p>
        <u>14.</u>
        <u>　お問い合わせ</u>
      </p>
      <p>
        開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。
      </p>
      <p>〒150-0002</p>
      <p>InCloud株式会社　　東京都渋谷区渋谷2-2-4青山アルコーブ405</p>
      <p>電話番号 ：03-6450-5245</p>
      <p>E-mail&nbsp;&nbsp;&nbsp;&nbsp; ：contact@incloud.jp</p>
      <p>（なお、受付時間は、平日10時から18時までとさせていただきます。）</p>
      <p>
        <u>15.</u>
        <u>　継続的改善</u>
      </p>
      <p>
        当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。
      </p>
      <p>
        16.　<u>十分性認定に基づき提供を受けた個人データの取扱</u>
        <u>い</u>
      </p>
      <p>
        下記に定義する十分性認定に基づき提供を受けた個人データについては、前各項の規定に加え、以下の規定が適用され、本項の規定が前各項の規定と異なる場合は本項が優先します。
      </p>
      <p>16.1&nbsp;&nbsp; 定義</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「EU」とは、欧州連合加盟国及び欧州経済領域（EEA: European Economic
        Area）協定に基づきアイスランド、リヒテンシュタイン及びノルウェーを含む、欧州連合（European
        Union）を意味します。
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「GDPR」とは、個人データの取扱いに係る自然人の保護及び当該データの自由な移転に関する欧州議会および欧州理事会規則（一般データ保護規則）（REGULATION
        OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL on the protection of
        natural persons with regard to the processing of personal data and on
        the free movement of such data, and repealing Directive
        95/46/EC（General Data Protection Regulation））を意味します。
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「十分性認定」とは、GDPR第 45
        条に基づき、欧州委員会が、国又は地域等を個人データについて十分な保護水準を確保していると認める決定を意味します。
      </p>
      <p>
        16.2&nbsp;&nbsp;
        当社が、EU域内から十分性認定に基づき提供を受けた個人データに、GDPRにおいて「特別の種類の個人データ」（Special
        Categories of Personal
        Data）と定義されている、性生活、性的指向又は労働組合に関する情報が含まれている場合には、当該情報について要配慮個人情報として取り扱うものとします。
      </p>
      <p>
        16.3&nbsp;&nbsp;
        当社が、EU域内から十分性認定に基づき提供を受けた個人データについては、消去することとしている期間にかかわらず、個人情報保護法第2条第7項における「その存否が明らかになることにより公益その他の利益が害されるものとして政令で定めるもの」に該当しない限り、同項に定める保有個人データとして取り扱うものとします。
      </p>
      <p>
        16.4&nbsp;&nbsp;
        当社が、EU域内から十分性認定に基づき個人データの提供を受ける場合、個人情報保護法第26条第1項及び第3項の規定に基づき、EU域内から当該個人データの提供を受ける際に特定された利用目的を含め、その取得の経緯を確認し、記録することとする。
      </p>
      <p>
        16.5　
        当社が、EU域内から十分性認定に基づき個人データの提供を受けた他の個人情報取扱事業者から、当該個人データの提供を受ける場合は、個人情報保護法第26条第１項及び第３項の規定に基づき、当該個人データの提供を受ける際に特定された利用目的を含め、その取得の経緯を確認し、記録することとする。
      </p>
      <p>
        16.6&nbsp;&nbsp;
        当社は、16.4又は16.5に基づき確認し、記録した個人データについては、当初又はその後提供を受ける際に特定された利用目的の範囲内で利用目的を特定し、その範囲内で当該個人データを利用するものとする。
      </p>
      <p>
        16.7&nbsp;&nbsp;
        当社は、EU域内から十分性認定に基づき提供を受けた個人データを外国にある第三者へ提供するに当たっては、7.2項に基づく本人の同意の取得に関し、本人が同意に係る判断を行うために必要な移転先の状況についての情報を提供した上で、あらかじめ外国にある第三者への個人データの提供を認める旨の本人の同意を得るものとします。
      </p>
      <p>
        16.8&nbsp;&nbsp;
        EU域内から十分性認定に基づき提供を受けた個人データについては、加工方法等情報（匿名加工情報の作成に用いた個人情報から削除した記述等及び個人
        識別符号並びに個人情報保護法第 36 条第 1
        項の規定により行った加工の方法に関する情報（その情報を用いて当該個人情報を復元することができるものに限る。）をいう。）を削除することにより、匿名化された個人を再識別することを何人にとっても不可能とした場合に限り、匿名加工情報とみなします。
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p style={{ textAlign: "right" }}>【2020年5月11日制定】</p>
    </div>
  );
}

export default PpJa;
