import React from "react";

function TosJa() {
  return (
    <div className="agreement-text">
      <p>
        本利用規約（以下「本規約」といいます。）には、InCloud株式会社（以下「当社」といいます。）の提供する本サービス（第2条に定義）のご利用にあたり、ユーザーの皆様に遵守していただかなければならない事項及び当社とユーザーの皆様との間の権利義務関係が定められております。本サービスをご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <u>適　用</u>
        </li>
      </ul>
      <ol>
        <li>
          本規約は、本サービスの利用に関する当社と登録ユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、登録ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
        </li>
        <li>
          当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
        </li>
      </ol>
      <ul>
        <li>
          <u>定　義</u>
        </li>
      </ul>
      <p>
        本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
      </p>
      <p>
        (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「QAシート」とは、本サービスにおいてメンバーがプロジェクトに関してコミュニケーションを行うためのスプレッドシートを意味します。
      </p>
      <p>
        (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「月間契約」とは、本サービスの1ヶ月分のご利用料金をお支払い頂き、本サービスを1ヶ月間ご利用頂くプランを意味します。
      </p>
      <p>
        (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「上限プロジェクト数」とは、本サービスにおいて同時に進行できるプロジェクト数を意味します。
      </p>
      <p>
        (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
      </p>
      <p>
        (5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「当社ウェブサイト」とは、そのドメインが「
        <a href="http://www.sqa.jp">www.sqa.jp</a>
        」（これに関連するドメインを含みます。）である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
      </p>
      <p>
        (6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「登録希望者」とは、第3条において定義された「登録希望者」を意味します。
      </p>
      <p>
        (7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「登録情報」とは、第3条において定義された「登録情報」を意味します。
      </p>
      <p>
        (8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「登録ユーザー」とは、第3条に基づき本サービスの利用者としての登録がなされた個人又は法人を意味します。
      </p>
      <p>
        (9)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        「年間契約」とは、本サービスの1年間分のご利用料金をお支払い頂き、本サービスを1年間ご利用頂くプランを意味します。
      </p>
      <p>
        (10)&nbsp;&nbsp;
        「プロジェクト」とは、本サービスにおけるQAシートで扱う案件を意味し、一つのプロジェクトに対して一つのQAシートがご利用頂けます。
      </p>
      <p>
        (11)&nbsp;&nbsp;
        「プロプラン」とは、第4項第1項第3号に定める内容にて、本サービスを利用することができる有料の利用形態のことを意味します。
      </p>
      <p>
        (12)&nbsp;&nbsp;
        「ベーシックプラン」とは、第4項第1項第2号に定める内容にて、本サービスを利用することができる有料の利用形態のことを意味します。
      </p>
      <p>
        (13)&nbsp;&nbsp;
        「本サービス」とは、当社が提供するSmartQAという名称のQ&amp;Aに関するスプレッドシートを複数人間で共有及び書込み並びにタスク管理をすることができるクラウド型のサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
      </p>
      <p>
        (14)&nbsp;&nbsp;
        「無料プラン」とは、第4項第1項第3号に定める内容にて、本サービスを無料で利用することができる利用形態のことを意味します。
      </p>
      <p>
        (15)&nbsp;&nbsp;
        「メンバー」とは、本サービスのQAシートを利用する個人を意味します。
      </p>
      <p>
        (16)&nbsp;&nbsp;
        「利用契約」とは、第3条第4項に定義される「利用契約」を意味します。
      </p>
      <p>
        (17)&nbsp;&nbsp;
        「外部SNSサービス」とは、Facebook、その他の他の事業者が提供している当社所定のソーシャル・ネットワーキング・サービスで、登録ユーザーの認証、友人関係の開示、当該外部ソーシャル・ネットワーク内へのコンテンツの公開などの機能を持ち、本サービスの実施に利用されるサービスを意味します。
      </p>
      <p>
        (18)&nbsp;&nbsp;
        「外部SNS事業者」とは、外部SNSサービスのサービス提供者を意味します。
      </p>
      <p>
        (19)&nbsp;&nbsp;
        「外部SNS利用規約」とは、登録ユーザーと外部SNS事業者との権利関係を定める規約を意味します。
      </p>
      <ul>
        <li>
          <u>登　録</u>
        </li>
      </ul>
      <ol>
        <li>
          本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
        </li>
        <li>
          登録の申請は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
        </li>
        <li>
          当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
        </li>
      </ol>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        本規約に違反するおそれがあると当社が判断した場合
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        過去に本サービスの利用の登録を取り消された者である場合
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        登録希望者が個人の場合で、未成年者、成年被後見人、被保佐人又は被補助人のいずれかであ&nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        り、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; (5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        反社会的勢力等（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、社会運動等&nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        標ぼうゴロ、特殊知能暴力集団その他暴力、威力又は詐欺的手法を使用して経済的利益を追求&nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        する集団又は個人を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的&nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流&nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        若しくは関与を行っていると当社が判断した場合
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; (6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        その他、当社が登録を適当でないと合理的に判断した場合
      </p>
      <ol start="4">
        <li>
          登録希望者は、本サービスの利用の登録を申請する際に、本サービスにおいて使用するプラン及び契約期間を選択するものとします。
        </li>
        <li>
          当社は、前項その他当社の基準に従って、登録希望者の登録及び選択されたプランの利用の可否を判断し、当社が登録及び当該プランの利用を認める場合にはその旨を登録希望者に通知します。かかる通知により登録希望者の登録ユーザーとしての登録は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）が登録ユーザーと当社の間に成立します。
        </li>
        <li>
          登録ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
        </li>
      </ol>
      <ul>
        <li>
          <u>本サービスの利用及び各プランの内容</u>
        </li>
      </ul>
      <ol>
        <li>
          登録ユーザーは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
        </li>
        <li>
          本サービスで利用できるプラン及びその基本的な内容は下記のとおりです。以下に定める内容の他、当社が各プランにおいて別途追加する機能を使用できることがあります。
        </li>
      </ol>
      <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 無料プラン</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        上限プロジェクト数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        3個
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        メンバー&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        本サービスを利用できるメンバー3名以内
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ストレージ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        なし（ファイルのアップロードのみ可能）
      </p>
      <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ベーシックプラン</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        上限プロジェクト数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        10個
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        メンバー&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        各プロジェクトに参加できるメンバー5名以内
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ストレージ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ファイルのアップロード用に250 MB
      </p>
      <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; プロプラン</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        上限プロジェクト数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        無制限
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        メンバー&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        無制限
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ストレージ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ファイルアップロード用の5GB
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        その他&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        QAシートの内容をCSVにエクスポートできます。
      </p>
      <p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; プロプラン（自動翻訳機能付き）</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        上限プロジェクト数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        無制限
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        メンバー&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        無制限
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ストレージ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ファイルアップロード用の5GB
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        自動翻訳機能&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Google翻訳のAPIを利用した翻訳機能
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        その他&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        QAシートの内容をCSVにエクスポートできます。
      </p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          登録ユーザーは、プラン及び契約期間を当社の定める方法に従って変更できるものとします。但し、年間契約月間契約間でのそれぞれの変更は、各契約期間満了日の1ヶ月以上前に申し込むことはできません。
        </li>
        <li>
          登録ユーザーは、プランごとに定められている上限数の範囲内で、複数の個人をメンバーとして登録することができ、メンバーを変更すること（既存のメンバーの登録を削除し、別の個人をメンバーとして登録すること）ができます。
        </li>
        <li>
          登録ユーザーは、メンバーに、本規約及び当社が定める本サービスの利用方法を遵守させるものとし（この場合、必要に応じて「登録ユーザー」を「メンバー」と読み替えるものとします。）、メンバーの行為は登録ユーザーの行為とみなし、メンバーが本規約又は当社が定める本サービスの利用方法に違反した場合は、登録ユーザーの違反とみなします。
        </li>
      </ol>
      <ul>
        <li>
          <u>料金及び支払方法</u>
        </li>
      </ul>
      <ol>
        <li>
          登録ユーザーは、本サービス利用の対価として、下記に定める利用料金（消費税別）を負担するものとします。
        </li>
      </ol>
      <p>記</p>
      <p>
        (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        無料プラン&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        無料
      </p>
      <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ベーシックプラン</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        月間契約&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        380円/月
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        年間契約&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        3,800円/年
      </p>
      <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; プロプラン</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        月間契約&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        780円/月
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        年間契約&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        7,800円/年
      </p>
      <p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; プロプラン（自動翻訳機能付き）</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        月間契約&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ●円/月
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        年間契約&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        ●円/年
      </p>
      <ol start="2">
        <li>
          登録ユーザーは各プランの利用開始及び変更のお申し込み後、当該プランの利用料金を、クレジットカードを利用した決済手段（Stripeを含む。）、当社が別途指定する銀行口座への振込送金（但し、登録ユーザーが法人の場合に限ります。）又は別途当社の指定する方法で当社に支払うものとします。登録ユーザーは、クレジットカード決済による場合は、お申し込みのときに、別途当社の指定する方法でお支払いする場合は、当社が別途指定する日までに、当該利用料金を支払うものとします。振込手数料その他支払に必要な費用は登録ユーザーの負担とします。
        </li>
        <li>
          登録ユーザーは、本サービスの利用の有無にかかわらず、契約期間内の利用料金全額を支払うものとします。契約期間中に利用契約が終了する場合に、既にお支払いいただいた利用料金があるときでも、当社から登録ユーザーへの返金は致しません。
        </li>
        <li>
          プラン又は契約期間の変更の場合、変更前のプランの利用期間と変更後のプランの利用期間が重複する場合には、変更前のプランに関する期間終了後に変更後のプランイン変更されるものとし、変更前のプランの期間中にプランが変更された場合でも、利用料金の割引及び返金などは行われません。同様に、月間契約と年間契約の変更についても、契約期間中に変更が生じた場合でも、当該変更前の契約期間が終了するまで利用料金の変更はなされず、利用料金の割引・返金等は行われません。
        </li>
        <li>
          契約期間中に利用契約が解約された又は本サービスの提供の停止若しくは終了した場合であっても、本サービスの料金は全額お支払いいただくものとし、既にお支払いいただいた利用料金がある場合でも返金は致しません。但し、当社の都合又は当社の責めに帰すべき事由により、契約期間中に利用契約が解約された又は本サービスの提供の停止若しくは終了した場合は、この限りではありません。
        </li>
        <li>
          登録ユーザーが利用料金の支払を遅滞した場合、登録ユーザーは年14.6％の割合による遅延損害金を当社に支払うものとします。
        </li>
      </ol>
      <ul>
        <li>
          <u>アカウント情報の管理</u>
        </li>
      </ul>
      <ol>
        <li>
          登録ユーザーは、自己の責任において、本サービスにかかるユーザーID及びパスワード（以下「アカウント情報」といいます。）を管理及び保管するものとし、これを第三者（メンバーを除きます。）に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
        </li>
        <li>
          アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーが負うものとし、当社に責めに帰すべき事由がある場合を除き、当社は一切の責任を負いません。
        </li>
        <li>
          登録ユーザーは、
          アカウント情報が盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
        </li>
      </ol>
      <ul>
        <li>
          <u>禁止行為</u>
        </li>
      </ul>
      <ol>
        <li>
          登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
        </li>
      </ol>
      <p>
        (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        当社、又は他の登録ユーザー、外部SNS事業者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
      </p>
      <p>
        (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        犯罪行為に関連する行為又は公序良俗に反する行為
      </p>
      <p>
        (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        猥褻な情報又は青少年に有害な情報を送信する行為
      </p>
      <p>
        (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 異性交際に関する情報を送信する行為
      </p>
      <p>
        (5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        法令又は当社若しくは登録ユーザーが所属する業界団体の内部規則に違反する行為
      </p>
      <p>
        (6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
      </p>
      <p>
        (7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為
      </p>
      <p>
        (8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        当社による本サービスの運営を妨害するおそれのあると合理的に認められる行為
      </p>
      <p>
        (9)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        その他、当社が不適切と合理的に判断する行為
      </p>
      <ol start="2">
        <li>
          当社は、本サービスにおける登録ユーザーによる情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が合理的に判断した場合には、登録ユーザーに事前に通知することなく、当該情報の全部又は一部について削除、送信停止その他の措置をとることができるものとします。当社は、本項に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
        </li>
      </ol>
      <ul>
        <li>
          <u>本サービスの停止等</u>
        </li>
      </ul>
      <ol>
        <li>
          当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
        </li>
      </ol>
      <p>
        (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
      </p>
      <p>
        (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        コンピューター、通信回線等が事故により停止した場合
      </p>
      <p>
        (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
      </p>
      <p>
        (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        外部SNSサービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
      </p>
      <p>
        (5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        その他、当社が停止又は中断を合理的に必要と判断した場合
      </p>
      <ol start="2">
        <li>
          当社は、当社の合理的な判断により、本サービスの提供を終了することができます。この場合、当社は登録ユーザーに事前に通知するものとします。
        </li>
        <li>
          当社は、本条に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
        </li>
      </ol>
      <ul>
        <li>
          <u>設備の負担等</u>
        </li>
      </ul>
      <ol>
        <li>
          本サービスの提供を受けるために必要な、コンピューター、スマートフォン、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、登録ユーザーの費用と責任において行うものとします。
        </li>
        <li>
          登録ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
        </li>
        <li>
          当社は、登録ユーザーが送受信したメッセージその他の情報を利用期間中及び利用契約終了後も一定期間保存することがありますが、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。そのため、登録ユーザーは、QAシート等本サービス上に投稿した情報については、自己の責任及び費用によって、バックアップその他の保存をしなければならないものとします。
        </li>
        <li>
          登録ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等を登録ユーザーのコンピューター、スマートフォン等にインストールする場合には、登録ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとします。
        </li>
      </ol>
      <ul>
        <li>
          <u>権利帰属</u>
        </li>
      </ul>
      <ol>
        <li>
          当社ウェブサイト及び本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本規約において明示されているものを除き、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。登録ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
        </li>
        <li>
          当社ウェブサイト又は本サービスにおいて、登録ユーザーが投稿その他送信を行った文章、画像、動画その他のデータ（以下「送信情報」といいます。）については、登録ユーザーに帰属するものとしますが、利用契約期間中及び利用期間終了後、当社において、送信情報を閲覧することができ、本サービスを提供及び運営並びに当社における新サービスの開発のために当該送信情報のうち登録ユーザーの氏名（個人の場合）及び名称（法人の場合）、メンバーの氏名、使用するメンバーの所属部署名、電子メールアドレス、本サービスの使用状況に関する統計を除く情報を無償で利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとします。
        </li>
        <li>
          当社は、以下の各号のいずれかに該当する場合には、合理的な範囲で、送信情報を閲覧し、第三者に提供することができるものとします。
        </li>
      </ol>
      <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 契約者の同意を得た場合</p>
      <p>
        (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        裁判所、捜査機関その他の行政機関からの要請がある場合
      </p>
      <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 法令に従い開示の義務を負う場合</p>
      <p>
        (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        登録ユーザーが利用契約に違反した場合又は違反するおそれがあると当社が合理的に判断した場合
      </p>
      <p>
        (5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        生命、身体、その他重要な権利を保護するために必要と当社が合理的に判断する場合
      </p>
      <p>
        (6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        本サービスのメンテナンスのため緊急の必要がある場合
      </p>
      <p>(7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 上記各号に準じる必要性がある場合</p>
      <ol start="4">
        <li>
          当社は、以下各号のいずれかに該当する場合には、送信情報について、その一部又は全部を削除することができるものとします。この場合、当社は、削除された送信情報について、当該情報の復旧を含めて一切責任を負いません。
        </li>
      </ol>
      <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 登録ユーザーの同意を得た場合</p>
      <p>
        (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        登録ユーザーが利用契約に違反した場合又は違反するおそれがあると当社が合理的に判断した場合
      </p>
      <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 利用契約が終了した場合</p>
      <p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 本サービスが終了した場合</p>
      <p>
        (5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        フリープランを利用する登録ユーザーが本サービスに1年以上ログインしなかった場合
      </p>
      <p>(6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 上記各号に準じる必要性がある場合</p>
      <ol start="5">
        <li>
          全各項の規定にかかわらず、当社は、当社の裁量で、利用契約終了後の登録ユーザーのサポートのために、送信情報を保存することができるものとします。
        </li>
      </ol>
      <ul>
        <li>
          <u>登録取消等</u>
        </li>
      </ul>
      <ol>
        <li>
          当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該登録ユーザーについて本サービスの利用を一時的に停止し、又は登録ユーザーとしての登録を取り消すことができます。
        </li>
      </ol>
      <p>
        (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 本規約のいずれかの条項に違反した場合
      </p>
      <p>
        (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        登録情報に虚偽の事実があることが判明した場合
      </p>
      <p>
        (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        当社、他の登録ユーザー、外部SNS事業者その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
      </p>
      <p>
        (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        外部SNS利用規約に違反したことその他の理由によって、登録ユーザーが外部SNS事業者から、そのサービスの提供や連携を受けられなくなった場合
      </p>
      <p>
        (5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        手段の如何を問わず、本サービスの運営を妨害した場合
      </p>
      <p>
        (6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
      </p>
      <p>
        (7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
      </p>
      <p>
        (8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
      </p>
      <p>(9)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 租税公課の滞納処分を受けた場合</p>
      <p>
        (10)&nbsp;&nbsp;&nbsp;
        死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
      </p>
      <p>(11)&nbsp;&nbsp;&nbsp; 利用料金の支払いがない場合</p>
      <p>(12)&nbsp;&nbsp;&nbsp; 第3条第3項各号に該当する場合</p>
      <p>
        (13)&nbsp;&nbsp;&nbsp;
        その他、当社が登録ユーザーとしての登録の継続を適当でないと合理的に判断した場合
      </p>
      <ol start="2">
        <li>
          前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
        </li>
        <li>
          登録ユーザーは、当社所定の方法で当社に通知することにより、登録ユーザーの登録を取り消すことができます。
        </li>
        <li>
          当社は、本条に基づき当社が行った行為により登録ユーザーに生じた損害について、一切の責任を負いません。
        </li>
        <li>
          本条に基づき登録ユーザーの登録が取り消された場合、登録ユーザーは、当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他の物につき、返還、廃棄その他の処分を行うものとします。
        </li>
      </ol>
      <ul>
        <li>
          <u>保証の否認及び免責</u>
        </li>
      </ul>
      <ol>
        <li>
          当社は、QAシートの内容及び本サービスにおいてアップロードされたデータが、本サービスにおいて正常に保存されること及び消失しないことにつき如何なる保証も行うものではありません。登録ユーザーは、QAシートの内容及び本サービスにおいてアップロードされたデータをバックアップする等により自己の責任で保存しなければならないものとし、当社は、当社の責めに帰すべき事由がある場合を除き、QAシートの内容及び本サービスにおいてアップロードされたデータが、正常に保存されていなかった又は消失したとしても、一切の責任を負いません。
        </li>
        <li>
          本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。また、QAシート及び本サービスにおいてアップロードされたデータ及び書き込まれた情報の内容の正確性及び適法性について、当社は一切の責任を負いません。
        </li>
        <li>
          登録ユーザーが当社から直接又は間接に、本サービス、当社ウェブサイト、本サービスの他の登録ユーザーその他の事項に関する何らかの情報を得た場合であっても、当社は登録ユーザーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
        </li>
        <li>
          本サービスは、外部SNSサービスと連携することがありますが、かかる連携を保証するものではなく、外部SNSサービスとの連携の支障等について、当社の責に帰すべき事由がある場合を除き、当社は一切の責任を負いません。
        </li>
        <li>
          本サービスが外部SNSサービスと連携している場合において、登録ユーザーは外部SNS利用規約を自己の費用と責任で遵守するものとし、その違反によって登録ユーザーと当該外部SNSサービスを運営する外部SNS事業者との間で紛争等が生じた場合でも、当社は、当該紛争等について一切の責任を負いません。
        </li>
        <li>
          登録ユーザーは、本サービスを利用することが、登録ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、登録ユーザーによる本サービスの利用が、登録ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
        </li>
        <li>
          本サービス又は当社ウェブサイトに関連して登録ユーザーと他の登録ユーザー、外部SNS事業者その他の第三者との間において生じた取引、連絡、紛争等については、登録ユーザーの責任において処理及び解決するものとし、当社の責に帰すべき事由がある場合を除き、当社はかかる事項について一切責任を負いません。
        </li>
        <li>
          当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、登録ユーザーのメッセージ又は送信情報の削除又は消失､登録ユーザーの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録ユーザーが被った損害につき、当社の責に帰すべき事由がある場合を除き、賠償する責任を一切負わないものとします。
        </li>
        <li>
          当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して、当社の責に帰すべき事由がある場合を除き、一切の責任を負わないものとします。
        </li>
        <li>
          消費者契約法その他の強行法規の適用その他何らかの理由により、当社が登録ユーザーに対して損害賠償責任を負う場合においても、当社の賠償責任は、損害の事由が生じた時点から遡って過去6ヶ月の期間に登録ユーザーから現実に受領した本サービスの利用料金の総額を上限とします。
        </li>
      </ol>
      <ul>
        <li>
          <u>ユーザーの賠償等の責任</u>
        </li>
      </ul>
      <ol>
        <li>
          登録ユーザーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
        </li>
        <li>
          登録ユーザーが、本サービスに関連して他の登録ユーザー、外部SNS事業者その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、登録ユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
        </li>
        <li>
          登録ユーザーによる本サービスの利用に関連して、当社が、他の登録ユーザー、外部SNS事業者その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、登録ユーザーは当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
        </li>
      </ol>
      <ul>
        <li>
          <u>秘密保持</u>
        </li>
      </ul>
      <ol>
        <li>
          本規約において「秘密情報」とは、利用契約又は本サービスに関連して、登録ユーザーが、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、(1)当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)当社から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)当社から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。
        </li>
        <li>
          登録ユーザーは、秘密情報を本サービスの利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。
        </li>
        <li>
          第2項の定めに拘わらず、登録ユーザーは、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。
        </li>
        <li>
          登録ユーザーは、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には、事前に当社の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。
        </li>
        <li>
          登録ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。
        </li>
      </ol>
      <ul>
        <li>
          <u>個人情報等の取扱い</u>
        </li>
      </ul>
      <ol>
        <li>
          当社による登録ユーザーの個人情報（個人情報の保護に関する法律第2条第1項に定める「個人情報」を意味します。）の取扱いについては、別途定める当社のプライバシーポリシーの定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの個人情報を取扱うことについて同意するものとします。
        </li>
        <li>
          当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録ユーザーはこれに異議を述べないものとします。但し、当社は、登録ユーザーが当社に提供した、プロジェクトに関する情報及び資料については、厳に秘密として保持し、いかなる目的であっても第三者に対して提供しないものとする。
        </li>
      </ol>
      <ul>
        <li>
          <u>有効期間</u>
        </li>
      </ul>
      <ol>
        <li>
          利用契約は、登録ユーザーについて第3条に基づく登録が完了した日に効力を生じ、各プランの契約期間の満了、当該登録ユーザーの登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社と登録ユーザーとの間で有効に存続するものとします。
        </li>
        <li>
          月間契約の契約期間は、当社が登録ユーザーに対して通知する利用開始日から1ヶ月間とし、年間契約の契約期間は、当社が登録ユーザーに対して通知する利用開始日から1年間とします。なお、無料プランについては特に契約期間は定められておりませんが、当社においてストレージを確保する必要があることその他合理的な理由がある場合には、当社が登録ユーザーに14日前に通知することにより、無料プランに基づくユーザーとの利用契約を終了させることができるものとします。この場合、当社は当該終了に関して登録ユーザーに生じた損害について一切の責任を負わないものとします。
        </li>
        <li>
          契約期間満了日の前日までに、登録ユーザーから当社に対して、第11条第3項に定める解約手続きによって契約を終了させる旨の申し出がなされない限り、月間契約の場合は1ヶ月間自動更新、年間契約の場合は1年間自動更新されるものとし、その後も同様とします。
        </li>
      </ol>
      <ul>
        <li>
          <u>本規約等の変更</u>
        </li>
      </ul>
      <ol>
        <li>当社は、本サービスの内容を自由に変更できるものとします。</li>
        <li>
          当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更する場合には、変更の内容及び変更の効力発生時期を、当該効力発生時期までに当社所定の方法で告知するものとします。告知された効力発生時期以降に登録ユーザーが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、登録ユーザーは、本規約の変更に同意したものとみなします。
        </li>
      </ol>
      <ul>
        <li>
          <u>連絡/通知</u>
        </li>
      </ul>
      <p>
        本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。当社は、当社の定める方法以外の方法からの連絡については、対応しないものとします。
      </p>
      <ul>
        <li>
          <u>本規約の譲渡等</u>
        </li>
      </ul>
      <ol>
        <li>
          登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
        </li>
        <li>
          当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録情報その他の顧客情報を当該譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。
        </li>
      </ol>
      <ul>
        <li>
          <u>完全合意</u>
        </li>
      </ul>
      <p>
        本規約は、本規約に含まれる事項に関する当社と登録ユーザーとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社と登録ユーザーとの事前の合意、表明及び了解に優先します。
      </p>
      <ul>
        <li>
          <u>分離可能性</u>
        </li>
      </ul>
      <p>
        本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び登録ユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
      </p>
      <ul>
        <li>
          <u>存続規定</u>
        </li>
      </ul>
      <p>
        第4条第5項、第5条（未払がある場合に限ります。）、第6条第2項、第7条第2項、第8条第3項、第9条、第10条、第11条第2項、第4項及び第5項、第12条から第15条まで、第16条第2項、並びに第19条から第23条までの規定は利用契約の終了後も有効に存続するものとします。但し、第14条については、利用契約終了後2年間に限り存続するものとします。
      </p>
      <ul>
        <li style={{ textAlign: "left" }}>
          <u>準拠法及び管轄裁判所　</u>
        </li>
      </ul>
      <p>
        本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
      </p>
      <ul>
        <li>
          <u>協議解決</u>
        </li>
      </ul>
      <p>
        当社及び登録ユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
      </p>
      <p style={{ textAlign: "right" }}>【2020年5月11日制定】</p>
    </div>
  );
}

export default TosJa;
