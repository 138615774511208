import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, SectionHeading } from "../core";
import { FeatureBlock } from ".";

/**
 * FeaturesSection
 */
const FeaturesSection = ({
  sectionId,
  sectionTitle,
  block1Title,
  block1Text,
  block1Image,
  block1ButtonLabel,
  block2Title,
  block2Text,
  block2Image,
  block2ButtonLabel,
  block3Title,
  block3Text,
  block3Image,
  block3ButtonLabel,
}) => {
  return (
    <section id={sectionId} className={cx("bg-bg1", theme.spacing.sectionY)}>
      <Wrapper>
        <SectionHeading
          mainText={sectionTitle}
          textColor={theme.colors.accent1}
          subTextColor={theme.colors.accent2_80}
          lineColor={theme.colors.accent2}
        />

        <div className={cx("bg-transparent", theme.spacing.blockY)} />

        <div className="flex flex-col justify-center items-center">
          <FeatureBlock
            title={block1Title}
            text={block1Text}
            image={block1Image}
            buttonText={block1ButtonLabel}
          />

          <div className={cx("bg-transparent", theme.spacing.blockYLoose)} />

          <FeatureBlock
            title={block2Title}
            text={block2Text}
            image={block2Image}
            buttonText={block2ButtonLabel}
            isReverse={true}
          />

          <div className={cx("bg-transparent", theme.spacing.blockYLoose)} />

          <FeatureBlock
            title={block3Title}
            text={block3Text}
            image={block3Image}
            buttonText={block3ButtonLabel}
          />
        </div>
      </Wrapper>
    </section>
  );
};
FeaturesSection.propTypes = {
  sectionId: PropTypes.string,
  sectionTitle: PropTypes.string,
  block1Title: PropTypes.string,
  block1Text: PropTypes.string,
  block1Image: PropTypes.string,
  block1ButtonLabel: PropTypes.string,
  block2Title: PropTypes.string,
  block2Text: PropTypes.string,
  block2Image: PropTypes.string,
  block2ButtonLabel: PropTypes.string,
  block3Title: PropTypes.string,
  block3Text: PropTypes.string,
  block3Image: PropTypes.string,
  block3ButtonLabel: PropTypes.string,
};
FeaturesSection.defaultProps = {};

export default FeaturesSection;
